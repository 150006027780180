import { render, staticRenderFns } from "./default.vue?vue&type=template&id=9ce6b760&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/app/components/navigation.vue').default,FooterComp: require('/app/components/footer-comp.vue').default,PrivacyPolicyCookie: require('/app/components/privacy-policy-cookie.vue').default})
