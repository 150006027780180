//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      show: false,
      sections: {
        necessary: false,
        preferences: false,
        statistics: false,
        marketing: false,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.getCookieValue("policy").length > 0) {
        this.show = false;
      } else {
        setTimeout(() => {
          this.show = true;
        }, 1000 * 15);
      }
    });
  },
  methods: {
    getCookieValue(name) {
      return (
        document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
        ""
      );
    },
    setNecessary() {
      let data = {
        necessary: true,
        preferences: false,
        statistics: false,
        marketing: false,
      };
      document.cookie = `policy=${JSON.stringify(
        data
      )}; expires=${this.$moment().add(3, "M")}`;
      this.show = false;
    },
    setAll() {
      let data = {
        necessary: true,
        preferences: true,
        statistics: true,
        marketing: true,
      };
      document.cookie = `policy=${JSON.stringify(
        data
      )}; expires=${this.$moment().add(3, "M")}`;
      this.show = false;
    },
    setSection() {
      document.cookie = `policy=${JSON.stringify(
        this.sections
      )}; expires=${this.$moment().add(3, "M")}`;
      this.show = false;
    },
  },
};
