//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breadcrumb from "~/components/breadcrumb";
import customDropdown from "~/components/form-elements/custom-dropdown";
export default {
  name: "mobile-navigation",
  data() {
    return {
      navBread: [
        {
          text: "Home",
          href: "/",
        },
      ],
      isActive: false,
      searchKeyword: this.$router.history.current.query.keyword
        ? this.$router.history.current.query.keyword
        : "",
    };
  },
  components: { breadcrumb, customDropdown },
  mounted() {},
  methods: {
    toSearch(e) {
      e.preventDefault();
      if (this.searchKeyword != "") {
        this.$router.push({
          path: "/global-search-results",
          query: { keyword: this.searchKeyword, page: 1 },
        });
      }
    },
  },
  computed: {
    navBreadcrumb() {
      const value = [...this.navBread];
      const arrayCount = this.$route.path.split("/").length;
      if (this.$route.path != "/") {
        for (let step = 1; step < arrayCount; step++) {
          if (arrayCount < 6) {
            let data = {
              text: this.$route.path.split("/")[step].split("%20").join(" "),
              href: `${
                typeof this.$route.path.split("/")[step - 4] != "undefined" &&
                this.$route.path.split("/")[step - 4] != ""
                  ? `/${this.$route.path.split("/")[step - 4]}`
                  : ""
              }${
                typeof this.$route.path.split("/")[step - 3] != "undefined" &&
                this.$route.path.split("/")[step - 3] != ""
                  ? `/${this.$route.path.split("/")[step - 3]}`
                  : ""
              }${
                typeof this.$route.path.split("/")[step - 2] != "undefined" &&
                this.$route.path.split("/")[step - 2] != ""
                  ? `/${this.$route.path.split("/")[step - 2]}`
                  : ""
              }${
                typeof this.$route.path.split("/")[step - 1] != "undefined" &&
                this.$route.path.split("/")[step - 1] != ""
                  ? `/${this.$route.path.split("/")[step - 1]}`
                  : ""
              }/${this.$route.path.split("/")[step]}`,
            };
            value.push(data);
          } else {
            if (step == 1 || step == 2 || step == 3) {
              let data = {
                text: "...",
                href: "",
              };
              value.push(data);
            } else {
              let data = {
                text: this.$route.path
                  .split("/")
                  [step].split("%20")
                  .join(" ")
                  .substring(0, 18),
                href: `${
                  typeof this.$route.path.split("/")[step - 5] != "undefined" &&
                  this.$route.path.split("/")[step - 5] != ""
                    ? `/${this.$route.path.split("/")[step - 5]}`
                    : ""
                }${
                  typeof this.$route.path.split("/")[step - 4] != "undefined" &&
                  this.$route.path.split("/")[step - 4] != ""
                    ? `/${this.$route.path.split("/")[step - 4]}`
                    : ""
                }${
                  typeof this.$route.path.split("/")[step - 3] != "undefined" &&
                  this.$route.path.split("/")[step - 3] != ""
                    ? `/${this.$route.path.split("/")[step - 3]}`
                    : ""
                }${
                  typeof this.$route.path.split("/")[step - 2] != "undefined" &&
                  this.$route.path.split("/")[step - 2] != ""
                    ? `/${this.$route.path.split("/")[step - 2]}`
                    : ""
                }${
                  typeof this.$route.path.split("/")[step - 1] != "undefined" &&
                  this.$route.path.split("/")[step - 1] != ""
                    ? `/${this.$route.path.split("/")[step - 1]}`
                    : ""
                }/${this.$route.path.split("/")[step]}`,
              };
              value.push(data);
            }
          }
        }
        let lastItem = value[value.length - 1];
        lastItem.active = true;
        return value;
      } else {
        return null;
      }
    },
    countryAndLang() {
      return this.$store.state.languagesAndCountries;
    },
  },
};
