export const state = () => ({
    productByRange: null,
    currentCountry: "GLOBAL",
    currentLanguage: "text_en",
    tseriesdesigner: null,

});
export const mutations = {
    SET_PRODUCT_BY_RANGE(state, data) {
        state.productByRange = data
    },
    SET_TSERIES(state, data) {
        state.tseriesdesigner = data
    },
    CLEAR_TSERIES(state) {
        state.tseriesdesigner = null
    },
}

export const actions = {
    async getProductByRange({ commit, state }, payload) {
        await this.$axios.$get(`dmc-apis/get_products_by_range?lang=${state.currentLanguage}&rangeName=${payload.rangeName}`).then(response => {
            commit("SET_PRODUCT_BY_RANGE", response);
        });
    },
    async getTeaseries({ commit}, payload) {
        await this.$axios.$get(`dmc-apis/get_seasonal_flush_product_by_name/${payload.type}`).then(response => {
          commit("SET_TSERIES", response);
        });
      },
}