import _ from "lodash";

export const state = () => ({
  currentCountry: "GLOBAL",
  currentLanguage: "text_en",
  giftOfTeasPage: null,
  teaWaresPage: null,
  singleGiftOfTea: null,
  singleTeaAccessory: null,
  teaAccessoriesPage: null,
  teaAccessoriesCategories: null,
  productDetails: null,
  productDetailsName: null,
  recipeDetails: null,
});

export const mutations = {
  SET_GIFT_OF_TEAS(state, data) {
    state.giftOfTeasPage = data;
  },
  GET_SINGLE_GIFT_OF_TEA(state, data) {
    state.singleGiftOfTea = data;
  },
  SET_TEA_WARES(state, data) {
    state.teaWaresPage = data;
  },
  SET_TEA_ACCESSORIES(state, data) {
    state.teaAccessoriesPage = data;
  },
  GET_SINGLE_ACCESSORY(state, data) {
    state.singleTeaAccessory = data;
  },
  SET_TEA_ACCESSORIES_CATEGORY(state, data) {
    state.teaAccessoriesCategories = data;
  },
  SET_PRODUCT_DETAILS(state, data) {
    state.productDetails = data;
  },

  CLEAR_PRODUCT_DETAILS(state) {
    state.productDetails = null;
  },
  SET_PRODUCT_DETAILS_BY_NAME(state, data) {
    state.productDetailsName = data;
  },
  CLEAR_PRODUCT_DETAILS_BY_NAME(state) {
    state.productDetailsName = null;
  },
  SET_RECIPE_DETAILS(state, data) {
    state.recipeDetails = data;
  },
  CLEAR_RECIPE_DETAILS(state, data) {
    state.recipeDetails = null;
  },
  CLEAR_SINGLE_GIFT_OF_TEA(state) {
    state.singleGiftOfTea = null;
  },
  CLEAR_SINGLE_ACCESSORY(state) {
    state.singleTeaAccessory = null;
  },
};

export const actions = {
  async getGiftOfTeas({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/gift_of_teas?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}&country=${state.currentCountry}`
      )
      .then((response) => {
        commit("SET_GIFT_OF_TEAS", response);
      });
  },
  // async getSingleGiftOfTea({ commit }, payload) {
  //   await this.$axios
  //     .$get(`dmc-apis/gift_of_teas_by_id/${payload.teaId}`)
  //     .then((response) => {
  //       commit("GET_SINGLE_GIFT_OF_TEA", response);
  //     });
  // },
  async getSingleGiftOfTea({ commit }, id) {
    await this.$axios
      .$get(`dmc-apis/gift_of_teas_by_name/${id}`)
      .then((response) => {
        commit("GET_SINGLE_GIFT_OF_TEA", response);
      });
  },
  async getTeaWares({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/teaware?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}&country=${state.currentCountry}`
      )
      .then((response) => {
        commit("SET_TEA_WARES", response);
      });
  },
  getTeaAccessories: _.debounce(async function({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/get_accessories?page=${payload.page}&size=${payload.size}&material=${payload.material}&category=${payload.category}&lang=${state.currentLanguage}&country=${state.currentCountry}`
      )
      .then((response) => {
        console.log(response);
        commit("SET_TEA_ACCESSORIES", response);
      });
  }, 500),
  async getSingleTeaAccessory({ commit }, payload) {
    await this.$axios
      .$get(`dmc-apis/accessories_by_id/${payload.accessoryId}`)
      .then((response) => {
        commit("GET_SINGLE_ACCESSORY", response);
      });
  },
  async getTeaAccessoriesCategories({ commit }) {
    await this.$axios
      .$get(`dmc-apis/get_accessories_categories`)
      .then((response) => {
        commit("SET_TEA_ACCESSORIES_CATEGORY", response);
      });
  },
  async getProductDetails({ commit }, id) {
    await this.$axios
      .$get(`dmc-apis/get_product_by_id/${id}`)
      .then((response) => {
        commit("SET_PRODUCT_DETAILS", response);
      });
  },
  async getProductDetailsName({ commit }, name) {
    await this.$axios
      .$get(`dmc-apis/get_product_by_name/${name}`)
      .then((response) => {
        commit("SET_PRODUCT_DETAILS_BY_NAME", response);
        console.log(response);
      });
  },
  async getRecipes({ commit, state }, payload) {
    await this.$axios
      .$get(`dmc-apis/get_recipe_by_id/${payload}`)
      .then((response) => {
        commit("SET_RECIPE_DETAILS", response);
      });
  },
};
