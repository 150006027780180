import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import $ from "jquery"
// ..
AOS.init({
    offset: 0,
    once: true,
    duration: 600,
});

$(document).on('click', "#scroll-down", function (e) {
    $('html, body').animate({
        scrollTop: $(window).height() - 200
    }, 1200);
});