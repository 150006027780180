export const state = () => ({
    contactUs: null,
    career: null,
    siteMap: [],
    lifeDevotedTea: [],
    currentCountry: "GLOBAL",
    currentLanguage: "${rootState.currentLanguage}",
});

export const mutations = {
    SET_CONTACTUS(state, data) {
        state.contactUs = data
    },
    SET_CAREERS(state, data) {
        state.career = data
    },
    SET_SITEMAP(state, data) {
        state.siteMap = data
    },
    SET_LIFE_DEVOTED_TEA(state, data) {
        state.lifeDevotedTea = data
    }
}

export const actions = {

    // async nuxtServerInit({commit, dispatch }, {req}) {
    // },

    async getContactUsPage({ commit }) {
        const graphqlq = {
            query: `{
              contactUsPages(where: { country: "GLOBAL" }) {
                country
                page {
                  header_image_url
                  header {
                    text_en
                  }
                  contact_details {
                    contact_no_1
                    contact_no_2
                    email
                    latitude
                    longitude
                    address {
                      text_en
                    }
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      text_en
                    }
                    content {
                      text_en
                    }
                    link_text {
                      text_en
                    }
                    image_url
                  }
                  agreement_text{
                      text_en
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_CONTACTUS", response.data.contactUsPages);
        });
    },
    async getCareerPage({ commit }) {
        const graphqlq = {
            query: `{
              careerPages(where: { country: "GLOBAL" }) {
                country
                page {
                  sliders {
                    slider_has_video
                    video_url
                    image_url
                    header {
                      text_en
                    }
                  }
                  title_content {
                    has_header
                    has_link
                    link
                    header {
                      text_en
                    }
                    content {
                      text_en
                    }
                    link_text {
                      text_en
                    }
                    image_url
                  }
                  intern_content {
                    has_header
                    has_link
                    link
                    header {
                      text_en
                    }
                    content {
                      text_en
                    }
                    link_text {
                      text_en
                    }
                    image_url
                  }
                  vacancy_btn_text {
                    text_en
                  }
                  submit_cv_btn_text {
                    text_en
                  }
                  career_user_items {
                    image_url
                    name {
                      text_en
                    }
                    description {
                      text_en
                    }
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_CAREERS", response.data.careerPages);
        });
    },
    async submitContactUs({ commit }, data) {
        await this.$axios
            .$get(
                `/contact-us-submits`, data
            )
            .then((response) => {
                if (response.success) {

                }
            }, error => {

            })
    },
    async getSiteMap({ commit, rootState }) {
        const graphqlq = {
            query: `{
        siteMapPages (where:{
          country:"${rootState.currentCountry}"
          }){
          id
          country
          page{
            id
            site_map_sections{
              id
              section_title{
                ${rootState.currentLanguage}
                }
              section_items{
                image_url
                title{
                  ${rootState.currentLanguage}
                }
              link
              }
            }
            seo{     
              id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
          }
          }
        }
      }`
        }
        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_SITEMAP", response.data.siteMapPages[0]);
        });
    },
    async getLifeDevotedtea({ commit, rootState }) {
        const graphqlq = {
            query: `{
        aLifeDevotedToTeaPages(where:{
          country:"GLOBAL"
        }){
            id
            country
            page{
              id
              image_contents{ 
                has_header
                has_link
                link
                header{
                  ${rootState.currentLanguage}
                }
                content{
                  ${rootState.currentLanguage}
                }
                link_text{
                  ${rootState.currentLanguage} 
                }
                image_url
                sub_heading{
                  ${rootState.currentLanguage}
                }
                has_sub_heading
                has_video
                video_url}
              intro_content{ id
                has_header
                has_link
                link
                header{
                  ${rootState.currentLanguage}
                }
                content{
                ${rootState.currentLanguage}
                }
                link_text{
                ${rootState.currentLanguage}
                }
                image_url
                sub_heading{
                ${rootState.currentLanguage}
                }
                has_sub_heading
                has_video
                video_url}
              banner_image_url
              banner_title{
                ${rootState.currentLanguage}
              }
            }
          }
      }`
        }
        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_LIFE_DEVOTED_TEA", response.data.aLifeDevotedToTeaPages[0]);
        });
    },
}