export const state = () => ({
    sustainability: null,
    dilmahConservation: null,
    mjfFoundation: null,
});

export const mutations = {
    SET_SUSTAINABILITY(state, data) {
        state.sustainability = data
    },
    SET_DILMAH_CONSERVATION(state, data) {
        state.dilmahConservation = data
    },
    SET_MJF_FOUNDATION(state, data) {
        state.mjfFoundation = data
    }
}

export const actions = {
    async getSustainabilityPage({ commit }) {
        const graphqlq = {
            query: `{
            sustainabilityPages(where: { country: "GLOBAL" }) {
                id
                country
                page {
                  sliders {
                    slider_has_video
                    video_url
                    image_url
                    header {
                      text_en
                      text_du
                    }
                  }
                  contents {
                    has_header
                    has_link
                    link
                    header {
                      text_en
                      text_du
                    }
                    content {
                      text_en
                      text_du
                    }
                    link_text {
                      text_en
                      text_du
                    }
                    image_url
                  }
                  annual_report {
                    has_header
                    has_link
                    link
                    header {
                      text_en
                      text_du
                    }
                    content {
                      text_en
                      text_du
                    }
                    link_text {
                      text_en
                      text_du
                    }
                    image_url
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_SUSTAINABILITY", response.data.sustainabilityPages[0].page);
        });
    },
    async getDilmahConservationPage({ commit }) {
        const graphqlq = {
            query: `{
            dilmahConservationPages(where: { country: "GLOBAL" }) {
              id
              country
              page {
                sliders {
                  slider_has_video
                  video_url
                  image_url
                  header {
                    text_en
                  }
                }
                our_work {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                title_content{
                    has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                sustainability_components {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                seo{     
                  id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
              }
              }
            }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_DILMAH_CONSERVATION", response.data.dilmahConservationPages[0].page);
        });
    },
    async getMJFFoundationPage({ commit }) {
        const graphqlq = {
            query: `{
            mjfFoundationPages(where: { country: "GLOBAL" }) {
              id
              country
              page {
                sliders {
                  slider_has_video
                  video_url
                  image_url
                  header {
                    text_en
                  }
                }
                vision {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                  has_sub_heading
                  sub_heading{
                    text_en
                  }
                }
                purpose {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                  has_sub_heading
                  sub_heading{
                    text_en
                  }
                }
                sdg_s {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                videos {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                contributions {
                  has_header
                  has_link
                  link
                  header {
                    text_en
                  }
                  content {
                    text_en
                  }
                  link_text {
                    text_en
                  }
                  image_url
                }
                seo{     
                  id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
              }
              }
            }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_MJF_FOUNDATION", response.data.mjfFoundationPages[0].page);
        });
    }
}