export const state = () => ({
    teaBlog: []
  });
  
  export const mutations = {
    SET_TEA_BLOG(state, data) {
      state.teaBlog = data
    }
  }
  
  export const actions = {
  
    async getTeaBlog({ commit,state }) {
      const graphqlq = {
        query: `{
          teaBlogPages(where: { country: "GLOBAL" }) {
            country
            page {
              header_tea_blog {
                id
                image_url
                header {
                  text_en
                }
                long_description {
                  text_en
                }
                date
                is_featured
                short_description {
                  text_en
                }
                tea_blog_category {
                  id
                  name {
                    text_en
                  }
                }
                author {
                  text_en
                }
                share_count
              }
              dilmah_tea_blogs {
                id
                image_url
                header {
                  text_en
                }
                long_description {
                  text_en
                }
                date
                is_featured
                short_description {
                  text_en
                }
                tea_blog_category {
                  id
                  name {
                    text_en
                  }
                }
                author {
                  text_en
                }
                share_count
              }
            }
          }
        }`
      }
      await this.$axios.$post("/graphql", graphqlq).then(response => {
        commit("SET_TEA_BLOG", response.data.teaBlogPages);
      });
      }
  }