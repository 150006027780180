var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"z-index":"1000"}},[_c('b-row',{staticClass:"mobile-header position-relative",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"3","md":"2"}},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":"/images/logo.webp","alt":"logo image"}})])])]),_vm._v(" "),_c('b-col',{staticClass:"text-right",attrs:{"cols":"9","md":"10"}},[_c('div',{staticClass:"ml-2 icons-items"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.searchModal",modifiers:{"searchModal":true}}],attrs:{"role":"button","aria-labelledby":"labeldiv"}},[_c('span',{staticClass:"icon-search"})]),_vm._v(" "),_c('a',{attrs:{"href":"https://shop.dilmahtea.com/","target":"_blank","rel":"mobile"}},[_c('span',{staticClass:"icon-cart"})]),_vm._v(" "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"pr-0",attrs:{"data-src":"/images/menu.png","alt":"logo image"},on:{"click":function($event){_vm.isActive = !_vm.isActive}}})])]),_vm._v(" "),(!_vm.isActive)?_c('b-col',{staticClass:"d-flex justify-content-end pr-2 bredcumb-size",staticStyle:{"position":"absolute","z-index":"11","bottom":"-50px","right":"0","align-items":"center","height":"50px"},attrs:{"cols":"12"}},[_c('breadcrumb',{attrs:{"items":_vm.navBreadcrumb}})],1):_vm._e()],1),_vm._v(" "),_c('b-row',{staticClass:"mobile-second-navigation",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"justify-content-center",attrs:{"cols":"12"}},[(
          _vm.$route.path == '/dilmah-family/founders-message' ||
          _vm.$route.path == '/dilmah-family/dilmah-story' ||
          _vm.$route.path == '/dilmah-family/our-values' ||
          _vm.$route.path == '/dilmah-family/quality-certifications' ||
          _vm.$route.path == '/dilmah-family/our-tea-garden' ||
          _vm.$route.path == '/dilmah-family/what-we-do' ||
          _vm.$route.path == '/dilmah-family/leisure-hospitality' ||
          _vm.$route.path == '/dilmah-family/timeline' ||
          _vm.$route.path == '/dilmah-family/testimonials' ||
          _vm.$route.path == '/dilmah-family/awards'
        )?_c('div',{staticClass:"mt-2 mb-2 text-capitalize"},[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'founders-message'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/founders-message"}},[_c('span',{staticClass:"icon-document"}),_vm._v("Founder’s message")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-story'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/dilmah-story"}},[_c('span',{staticClass:"icon-book-mark"}),_vm._v("The Dilmah story")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'our-values'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/our-values"}},[_c('span',{staticClass:"icon-gem"}),_vm._v("Our values")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path == '/dilmah-family/quality-certifications' ||
            _vm.$route.path == '/dilmah-family/awards'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/quality-certifications"}},[_c('span',{staticClass:"icon-trophy"}),_vm._v("Quality certifications &\n          awards")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'our-tea-garden'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/our-tea-garden"}},[_c('span',{staticClass:"icon-leafs"}),_vm._v("Our tea gardens")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'what-we-do'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/what-we-do"}},[_c('span',{staticClass:"icon-hot-cup"}),_vm._v("What we do")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'leisure-hospitality'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/leisure-hospitality"}},[_c('span',{staticClass:"icon-park"}),_vm._v("Leisure &\n          "),_c('br'),_vm._v("hospitality")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'timeline'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/timeline"}},[_c('span',{staticClass:"icon-timeline"}),_vm._v("Dilmah global\n          timeline")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'testimonials'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/dilmah-family/testimonials"}},[_c('span',{staticClass:"icon-testimonial"}),_vm._v("Testimonials")])],1):_vm._e(),_vm._v(" "),(
          _vm.$route.path == '/all-about-tea/school-of-tea' ||
          _vm.$route.path == '/all-about-tea/school-of-tea-e-learning' ||
          _vm.$route.path == '/all-about-tea/infographic' ||
          _vm.$route.path == '/all-about-tea/tea-and-health' ||
          _vm.$route.path == '/all-about-tea/tea-inspired-e-books' ||
          _vm.$route.path == '/all-about-tea/facts-of-tea' ||
          _vm.$route.path == '/all-about-tea/dilmah-podcasts'
        )?_c('div',{staticClass:"mt-2 mb-2 text-capitalize"},[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'school-of-tea'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/school-of-tea"}},[_c('span',{staticClass:"icon-cap"}),_vm._v("School of Tea")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'school-of-tea-e-learning'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/school-of-tea-e-learning"}},[_c('span',{staticClass:"icon-study"}),_vm._v("School of Tea E-Learning")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'infographic'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/infographic"}},[_c('span',{staticClass:"icon-hot-cup"}),_vm._v("How to brew a perfect cup of\n          tea")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-and-health'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/tea-and-health"}},[_c('span',{staticClass:"icon-heart"}),_vm._v("Tea and Health")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-inspired-e-books'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/tea-inspired-e-books"}},[_c('span',{staticClass:"icon-elearning"}),_vm._v("Tea inspired E-books")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'facts-of-tea'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/facts-of-tea"}},[_c('span',{staticClass:"icon-ques-ans"}),_vm._v("Facts of Tea (FAQ)")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.historyofceylontea.com/","target":"_blank"}},[_c('span',{staticClass:"icon-history"}),_vm._v("History of Ceylon Tea")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-podcasts'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/all-about-tea/dilmah-podcasts"}},[_c('span',{staticClass:"icon-mic"}),_vm._v("Dilmah podcast")]),_vm._v(" "),_c('a',{attrs:{"href":"https://brewacademy.schooloftea.org/","target":"_blank"}},[_c('span',{staticClass:"icon-brew-academy"}),_vm._v("Dilmah Brew Academy")])],1):_vm._e(),_vm._v(" "),(
          _vm.$route.path == '/our-purpose/sustainability' ||
          _vm.$route.path == '/our-purpose/dilmah-conservation' ||
          _vm.$route.path == '/our-purpose/mjf-foundation'
        )?_c('div',{staticClass:"mt-2 mb-2 text-capitalize"},[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'sustainability'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/our-purpose/sustainability"}},[_c('span',{staticClass:"icon-earth"}),_vm._v("Sustainability")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-conservation'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/our-purpose/dilmah-conservation"}},[_c('span',{staticClass:"icon-ecology"}),_vm._v("Dilmah Conservation")]),_vm._v(" "),_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'mjf-foundation'
              ? 'nuxt-link-active nuxt-link-exact-active'
              : '',attrs:{"to":"/our-purpose/mjf-foundation"}},[_c('span',{staticClass:"icon-testimonial"}),_vm._v("MJF Foundation")])],1):_vm._e()])],1),_vm._v(" "),_c('div',{class:_vm.isActive ? 'mobile-sidebar-color' : '',on:{"click":function($event){_vm.isActive = !_vm.isActive}}}),_vm._v(" "),_c('div',{staticClass:"mobile-sidebar",class:{ active: _vm.isActive }},[_c('b-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"sidebar-close"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":"/images/close.png","alt":"logo image"},on:{"click":function($event){_vm.isActive = !_vm.isActive}}})]),_vm._v(" "),_c('b-col',{staticClass:"nav-item",attrs:{"cols":"12"}},[_c('ul',[_c('li',{staticClass:"nav-link"},[_c('a',{class:_vm.$route.path.split('/')[1] == 'dilmah-online'
                  ? 'exact-link-gold text-uppercase'
                  : 'text-uppercase',attrs:{"href":"/dilmah-online"}},[_vm._v("Dilmah Online")])]),_vm._v(" "),_c('li',{staticClass:"nav-link"},[_c('a',{class:_vm.$route.path.split('/')[1] == 'Careers'
                  ? 'exact-link-gold text-uppercase'
                  : 'text-uppercase',attrs:{"href":"/Careers"}},[_vm._v("Careers")])])])]),_vm._v(" "),_c('b-col',{staticClass:"nav-item second-item",attrs:{"cols":"12"}},[_c('div',{staticClass:"accordion second-item-main",attrs:{"role":"tablist"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],class:_vm.$route.path.split('/')[1] == 'dilmah-family'
                    ? 'exact-link'
                    : '',attrs:{"block":""}},[_vm._v("Dilmah Family")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.$route.path.split('/')[1] == 'dilmah-family',"id":"accordion-1","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'founders-message'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/founders-message"}},[_vm._v("Founder’s message")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-story'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/dilmah-story"}},[_vm._v("The Dilmah story")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'our-values'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/our-values"}},[_vm._v("Our values")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'quality-certifications'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/quality-certifications"}},[_vm._v("Quality certifications & awards")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'our-tea-garden'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/our-tea-garden"}},[_vm._v("Our tea gardens")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'what-we-do'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/what-we-do"}},[_vm._v("What we do")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'leisure-hospitality'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/leisure-hospitality"}},[_vm._v("Leisure & hospitality")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'timeline'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/timeline"}},[_vm._v("Dilmah global timeline")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'testimonials'
                          ? 'exact-link'
                          : '',attrs:{"to":"/dilmah-family/testimonials"}},[_vm._v("Testimonials")])],1)])])],1)],1),_vm._v(" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],class:_vm.$route.path.split('/')[1] == 'our-products'
                    ? 'exact-link'
                    : '',attrs:{"block":""}},[_vm._v("Our Products")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.$route.path.split('/')[1] == 'our-products',"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('div',{staticClass:"accordion second-item-main-sub",attrs:{"role":"tablist"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-11",modifiers:{"accordion-11":true}}],class:_vm.$route.path.split('/')[2] == 'our-teas'
                            ? 'exact-link'
                            : '',attrs:{"block":""}},[_vm._v("Our Teas")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"id":"accordion-11","accordion":"my-accordion-1","role":"tabpanel"}},[_c('b-card-body',[_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[3] == 'tea-ranges'
                                  ? 'exact-link'
                                  : '',attrs:{"to":"/our-products/our-teas/tea-ranges"}},[_vm._v("Tea Ranges")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[3] == 'tea-formats'
                                  ? 'exact-link'
                                  : '',attrs:{"to":"/our-products/our-teas/tea-formats"}},[_vm._v("Tea Formats")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[3] == 'tea-types'
                                  ? 'exact-link'
                                  : '',attrs:{"to":"/our-products/our-teas/tea-types"}},[_vm._v("Tea Types")])],1)])])],1)],1)],1),_vm._v(" "),_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'gift-of-teas'
                          ? 'exact-link'
                          : '',attrs:{"to":"/our-products/gift-of-teas"}},[_vm._v("Gift of Teas")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-accessories'
                          ? 'exact-link'
                          : '',attrs:{"to":"/our-products/tea-accessories"}},[_vm._v("Tea Accessories")])],1)])])],1)],1),_vm._v(" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}],class:_vm.$route.path.split('/')[1] == 'all-about-tea'
                    ? 'exact-link'
                    : '',attrs:{"block":""}},[_vm._v("All About Tea")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.$route.path.split('/')[1] == 'all-about-tea',"id":"accordion-3","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'school-of-tea'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/school-of-tea"}},[_vm._v("School of Tea")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] ==
                        'school-of-tea-e-learning'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/school-of-tea-e-learning"}},[_vm._v("School of Tea E-Learning")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'infographic'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/infographic"}},[_vm._v("How to brew a perfect cup of tea")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-and-health'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/tea-and-health"}},[_vm._v("Tea and Health")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-inspired-e-books'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/tea-inspired-e-books"}},[_vm._v("Tea inspired E-books")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'acts-of-tea'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/facts-of-tea"}},[_vm._v("Facts of Tea (FAQ)")])],1),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"https://www.historyofceylontea.com/","target":"_blank","rel":"mobile"}},[_vm._v("History of Ceylon Tea")])]),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-podcasts'
                          ? 'exact-link'
                          : '',attrs:{"to":"/all-about-tea/dilmah-podcasts"}},[_vm._v("Dilmah podcast")])],1),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"https://brewacademy.schooloftea.org/","target":"_blank","rel":"mobile"}},[_vm._v("Dilmah Brew Academy")])])])])],1)],1),_vm._v(" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-4",modifiers:{"accordion-4":true}}],class:_vm.$route.path.split('/')[1] == 'our-purpose'
                    ? 'exact-link'
                    : '',attrs:{"block":""}},[_vm._v("Our Purpose")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.$route.path.split('/')[1] == 'our-purpose',"id":"accordion-4","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('a',{attrs:{"href":"https://www.dilmahtea.com/sustainability/","target":"_blank","rel":"mobile"}},[_vm._v("Sustainability")])]),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-conservation'
                          ? 'exact-link'
                          : '',attrs:{"to":"/our-purpose/dilmah-conservation"}},[_vm._v("Dilmah Conservation")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'mjf-foundation'
                          ? 'exact-link'
                          : '',attrs:{"to":"/our-purpose/mjf-foundation"}},[_vm._v("MJF Foundation")])],1)])])],1)],1),_vm._v(" "),_c('ul',[_c('li',{staticClass:"nav-link"},[_c('nuxt-link',{class:_vm.$route.path.split('/')[1] == 'media' ? 'exact-link' : '',attrs:{"to":"/media"}},[_vm._v("MEDIA")])],1)]),_vm._v(" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-6",modifiers:{"accordion-6":true}}],class:_vm.$route.path.split('/')[1] == 'tea-inspired'
                    ? 'exact-link'
                    : '',attrs:{"block":""}},[_vm._v("Tea Inspired")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.$route.path.split('/')[1] == 'tea-inspired',"id":"accordion-6","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('ul',{staticClass:"sub-nav-link"},[_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] ==
                        'dilmah-hospitality-and-food-service'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/dilmah-hospitality-and-food-service"}},[_vm._v("Dilmah in Hospitality and Food Service")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'chefs-and-the-tea-maker'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/chefs-and-the-tea-maker"}},[_vm._v("Tea Gastronomy")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] ==
                        'dilmah-tea-inspiration-for-the-21st-century'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/dilmah-tea-inspiration-for-the-21st-century"}},[_vm._v("Dilmah Tea Inspiration for the 21st Century")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-infive-senses'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/tea-infive-senses"}},[_vm._v("Tea in Five Senses")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'dilmah-real-high-tea'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/dilmah-real-high-tea"}},[_vm._v("Dilmah Real High Tea")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'tea-lounge'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/tea-lounge"}},[_vm._v("t-Lounge By Dilmah")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] ==
                        'mixologist-and-the-tea-maker'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/mixologist-and-the-tea-maker"}},[_vm._v("Tea Mixology")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.$route.path.split('/')[2] == 'prealbums'
                          ? 'exact-link'
                          : '',attrs:{"to":"/tea-inspired/prealbums"}},[_vm._v("Tea in the First Sense")])],1),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"https://competitions.dilmahtea.com/","target":"_blank","rel":"mobile"}},[_vm._v("Tea Inspired Competitions")])])])])],1)],1),_vm._v(" "),_c('ul',[_c('li',{staticClass:"nav-link"},[_c('nuxt-link',{class:_vm.$route.path.split('/')[1] == 'contact-us'
                    ? 'exact-link'
                    : '',attrs:{"to":"/contact-us"}},[_vm._v("Contact Us")])],1)])],1)])],1)],1),_vm._v(" "),_c('b-modal',{attrs:{"id":"searchModal","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"mobile-search"},[_c('div',{on:{"click":function($event){return _vm.$bvModal.hide('searchModal')}}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"close-icon",attrs:{"data-src":"/images/close.png","alt":"close image"}})]),_vm._v(" "),_c('div',{staticClass:"search-input-mobile"},[_c('b-form-input',{attrs:{"placeholder":"Search the tea world of Dilmah"},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}}),_vm._v(" "),_c('b-button',{staticClass:"search-button",attrs:{"type":"submit","size":"sm"},on:{"click":_vm.toSearch}},[_c('span',{staticClass:"icon-search",on:{"click":function($event){return _vm.$bvModal.hide('searchModal')}}})])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }