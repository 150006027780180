export const state = () => ({
    whereTobuy: null,
});

export const mutations = {
    SET_WHERE_TO_BUY(state, data) {
        state.whereTobuy = data.whereToBuyPages[0].page
    },
}

export const actions = {
    async getwheretobuy({ commit }) {
        const graphqlq = {
            query: `{
                whereToBuyPages (where:{country:"GLOBAL"}){
                    id
                    country
                    page{
                        title{ 
                            text_en
                        }
                      header_image_url
                      header_descrpiton{ 
                        title{
                            text_en
                        }
                        description{
                            text_en
                        }
                      }
                      map_location{
                        country_selection
                        latitude
                        longitude
                        description{
                            text_en
                        }
                    }
                    }
                  }
          }`
        }
        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_WHERE_TO_BUY", response.data);
        });
    },




}