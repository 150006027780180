import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67ed178c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _58dee93b = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _5d64a3b9 = () => interopDefault(import('../pages/dilmah-online.vue' /* webpackChunkName: "pages/dilmah-online" */))
const _f4f53d7e = () => interopDefault(import('../pages/global-search-results.vue' /* webpackChunkName: "pages/global-search-results" */))
const _72c0d926 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _573e6d73 = () => interopDefault(import('../pages/tea-jornal.vue' /* webpackChunkName: "pages/tea-jornal" */))
const _42e26892 = () => interopDefault(import('../pages/trade-landing/index.vue' /* webpackChunkName: "pages/trade-landing/index" */))
const _2accaa6d = () => interopDefault(import('../pages/all-about-tea/dilmah-podcasts/index.vue' /* webpackChunkName: "pages/all-about-tea/dilmah-podcasts/index" */))
const _7adb1995 = () => interopDefault(import('../pages/all-about-tea/facts-of-tea.vue' /* webpackChunkName: "pages/all-about-tea/facts-of-tea" */))
const _2fa37720 = () => interopDefault(import('../pages/all-about-tea/how-to-brew-a-perfect-cup-of-tea.vue' /* webpackChunkName: "pages/all-about-tea/how-to-brew-a-perfect-cup-of-tea" */))
const _56e42995 = () => interopDefault(import('../pages/all-about-tea/infographic.vue' /* webpackChunkName: "pages/all-about-tea/infographic" */))
const _6d5a5524 = () => interopDefault(import('../pages/all-about-tea/school-of-tea.vue' /* webpackChunkName: "pages/all-about-tea/school-of-tea" */))
const _4214c016 = () => interopDefault(import('../pages/all-about-tea/school-of-tea-e-learning.vue' /* webpackChunkName: "pages/all-about-tea/school-of-tea-e-learning" */))
const _70159fa4 = () => interopDefault(import('../pages/all-about-tea/tea-and-health.vue' /* webpackChunkName: "pages/all-about-tea/tea-and-health" */))
const _2af23994 = () => interopDefault(import('../pages/all-about-tea/tea-inspired-e-books/index.vue' /* webpackChunkName: "pages/all-about-tea/tea-inspired-e-books/index" */))
const _1b3578cb = () => interopDefault(import('../pages/dilmah-family/awards.vue' /* webpackChunkName: "pages/dilmah-family/awards" */))
const _08e0d46a = () => interopDefault(import('../pages/dilmah-family/dilmah-story.vue' /* webpackChunkName: "pages/dilmah-family/dilmah-story" */))
const _00c184b3 = () => interopDefault(import('../pages/dilmah-family/founders-message.vue' /* webpackChunkName: "pages/dilmah-family/founders-message" */))
const _ed5dfe72 = () => interopDefault(import('../pages/dilmah-family/leisure-hospitality.vue' /* webpackChunkName: "pages/dilmah-family/leisure-hospitality" */))
const _7596506a = () => interopDefault(import('../pages/dilmah-family/our-tea-garden/index.vue' /* webpackChunkName: "pages/dilmah-family/our-tea-garden/index" */))
const _d297c650 = () => interopDefault(import('../pages/dilmah-family/our-values.vue' /* webpackChunkName: "pages/dilmah-family/our-values" */))
const _9e6823c8 = () => interopDefault(import('../pages/dilmah-family/quality-certifications.vue' /* webpackChunkName: "pages/dilmah-family/quality-certifications" */))
const _42fe9609 = () => interopDefault(import('../pages/dilmah-family/testimonials.vue' /* webpackChunkName: "pages/dilmah-family/testimonials" */))
const _17915296 = () => interopDefault(import('../pages/dilmah-family/timeline.vue' /* webpackChunkName: "pages/dilmah-family/timeline" */))
const _a6924a94 = () => interopDefault(import('../pages/dilmah-family/what-we-do.vue' /* webpackChunkName: "pages/dilmah-family/what-we-do" */))
const _40cf4ad2 = () => interopDefault(import('../pages/media/audios.vue' /* webpackChunkName: "pages/media/audios" */))
const _eedccf4e = () => interopDefault(import('../pages/media/dilmah-in-press/index.vue' /* webpackChunkName: "pages/media/dilmah-in-press/index" */))
const _7f693e06 = () => interopDefault(import('../pages/media/dilmah-tea-blog/index.vue' /* webpackChunkName: "pages/media/dilmah-tea-blog/index" */))
const _7f3e1ade = () => interopDefault(import('../pages/media/events/index.vue' /* webpackChunkName: "pages/media/events/index" */))
const _c506ada0 = () => interopDefault(import('../pages/media/images/index.vue' /* webpackChunkName: "pages/media/images/index" */))
const _c87c01ea = () => interopDefault(import('../pages/media/news/index.vue' /* webpackChunkName: "pages/media/news/index" */))
const _f7f11d66 = () => interopDefault(import('../pages/media/videos.vue' /* webpackChunkName: "pages/media/videos" */))
const _044dd13a = () => interopDefault(import('../pages/not-use-pages/ebooks.vue' /* webpackChunkName: "pages/not-use-pages/ebooks" */))
const _3d3ceb46 = () => interopDefault(import('../pages/not-use-pages/ginger.vue' /* webpackChunkName: "pages/not-use-pages/ginger" */))
const _344db17a = () => interopDefault(import('../pages/not-use-pages/human-rights-policy.vue' /* webpackChunkName: "pages/not-use-pages/human-rights-policy" */))
const _2175087f = () => interopDefault(import('../pages/not-use-pages/infusions-selections.vue' /* webpackChunkName: "pages/not-use-pages/infusions-selections" */))
const _181159cc = () => interopDefault(import('../pages/not-use-pages/knowledge-and-training-dropdown.vue' /* webpackChunkName: "pages/not-use-pages/knowledge-and-training-dropdown" */))
const _0d427788 = () => interopDefault(import('../pages/not-use-pages/not-use-tea-inspired.vue' /* webpackChunkName: "pages/not-use-pages/not-use-tea-inspired" */))
const _587a42b8 = () => interopDefault(import('../pages/not-use-pages/our-infusions.vue' /* webpackChunkName: "pages/not-use-pages/our-infusions" */))
const _9d74c154 = () => interopDefault(import('../pages/not-use-pages/our-purpose-dropdown.vue' /* webpackChunkName: "pages/not-use-pages/our-purpose-dropdown" */))
const _bfe4383e = () => interopDefault(import('../pages/not-use-pages/premium-ceylon-tea-selection.vue' /* webpackChunkName: "pages/not-use-pages/premium-ceylon-tea-selection" */))
const _c32d876e = () => interopDefault(import('../pages/not-use-pages/product-information.vue' /* webpackChunkName: "pages/not-use-pages/product-information" */))
const _a8b95f44 = () => interopDefault(import('../pages/not-use-pages/real-tea-cordial-selection.vue' /* webpackChunkName: "pages/not-use-pages/real-tea-cordial-selection" */))
const _3dcebccc = () => interopDefault(import('../pages/not-use-pages/seasonal-flush.vue' /* webpackChunkName: "pages/not-use-pages/seasonal-flush" */))
const _ab2a4c9c = () => interopDefault(import('../pages/not-use-pages/seasonal-flush-product-detail.vue' /* webpackChunkName: "pages/not-use-pages/seasonal-flush-product-detail" */))
const _1eeef710 = () => interopDefault(import('../pages/not-use-pages/t-series-designer-gourmet-tea.vue' /* webpackChunkName: "pages/not-use-pages/t-series-designer-gourmet-tea" */))
const _fe6a43e2 = () => interopDefault(import('../pages/our-products/gift-of-teas/index.vue' /* webpackChunkName: "pages/our-products/gift-of-teas/index" */))
const _bbe9f7fc = () => interopDefault(import('../pages/our-products/our-teas-recommendation.vue' /* webpackChunkName: "pages/our-products/our-teas-recommendation" */))
const _bb9ca754 = () => interopDefault(import('../pages/our-products/tea-accessories/index.vue' /* webpackChunkName: "pages/our-products/tea-accessories/index" */))
const _951c5126 = () => interopDefault(import('../pages/our-products/where-to-buy/index.vue' /* webpackChunkName: "pages/our-products/where-to-buy/index" */))
const _10247c5e = () => interopDefault(import('../pages/our-purpose/dilmah-conservation.vue' /* webpackChunkName: "pages/our-purpose/dilmah-conservation" */))
const _4933ae53 = () => interopDefault(import('../pages/our-purpose/mjf-Foundation.vue' /* webpackChunkName: "pages/our-purpose/mjf-Foundation" */))
const _949b9d7a = () => interopDefault(import('../pages/our-purpose/sustainability.vue' /* webpackChunkName: "pages/our-purpose/sustainability" */))
const _0cab18d0 = () => interopDefault(import('../pages/privacy-and-terms/dilmah-anti-bribary-policy.vue' /* webpackChunkName: "pages/privacy-and-terms/dilmah-anti-bribary-policy" */))
const _4d8dadd6 = () => interopDefault(import('../pages/privacy-and-terms/dilmah-business-ethics-policy.vue' /* webpackChunkName: "pages/privacy-and-terms/dilmah-business-ethics-policy" */))
const _3cb51a35 = () => interopDefault(import('../pages/privacy-and-terms/dilmah-human-rights-policy.vue' /* webpackChunkName: "pages/privacy-and-terms/dilmah-human-rights-policy" */))
const _21666383 = () => interopDefault(import('../pages/privacy-and-terms/privacy-and-cookie-policy.vue' /* webpackChunkName: "pages/privacy-and-terms/privacy-and-cookie-policy" */))
const _1513094b = () => interopDefault(import('../pages/privacy-and-terms/terms-conditions.vue' /* webpackChunkName: "pages/privacy-and-terms/terms-conditions" */))
const _51ed7252 = () => interopDefault(import('../pages/social-share/fb.vue' /* webpackChunkName: "pages/social-share/fb" */))
const _7c2974d1 = () => interopDefault(import('../pages/tea-inspired/artists/index.vue' /* webpackChunkName: "pages/tea-inspired/artists/index" */))
const _e15eab52 = () => interopDefault(import('../pages/tea-inspired/chefs-and-the-tea-maker.vue' /* webpackChunkName: "pages/tea-inspired/chefs-and-the-tea-maker" */))
const _5d574ad2 = () => interopDefault(import('../pages/tea-inspired/dilmah-hospitality-and-food-service.vue' /* webpackChunkName: "pages/tea-inspired/dilmah-hospitality-and-food-service" */))
const _e9d365fc = () => interopDefault(import('../pages/tea-inspired/dilmah-real-high-tea.vue' /* webpackChunkName: "pages/tea-inspired/dilmah-real-high-tea" */))
const _4b5649ee = () => interopDefault(import('../pages/tea-inspired/dilmah-tea-inspiration-for-the-21st-century.vue' /* webpackChunkName: "pages/tea-inspired/dilmah-tea-inspiration-for-the-21st-century" */))
const _77f12a52 = () => interopDefault(import('../pages/tea-inspired/mediaphotographers/index.vue' /* webpackChunkName: "pages/tea-inspired/mediaphotographers/index" */))
const _cad47822 = () => interopDefault(import('../pages/tea-inspired/mixologist-and-the-tea-maker.vue' /* webpackChunkName: "pages/tea-inspired/mixologist-and-the-tea-maker" */))
const _3c8356cc = () => interopDefault(import('../pages/tea-inspired/prealbums/index.vue' /* webpackChunkName: "pages/tea-inspired/prealbums/index" */))
const _24872a00 = () => interopDefault(import('../pages/tea-inspired/tea-infive-senses.vue' /* webpackChunkName: "pages/tea-inspired/tea-infive-senses" */))
const _09c247a0 = () => interopDefault(import('../pages/tea-inspired/tea-inspiredme/index.vue' /* webpackChunkName: "pages/tea-inspired/tea-inspiredme/index" */))
const _6c396245 = () => interopDefault(import('../pages/tea-inspired/tea-lounge.vue' /* webpackChunkName: "pages/tea-inspired/tea-lounge" */))
const _301c1c3f = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/index.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/index" */))
const _6ee7a6f6 = () => interopDefault(import('../pages/trade-landing/dilmah-tea-for-fine-dining.vue' /* webpackChunkName: "pages/trade-landing/dilmah-tea-for-fine-dining" */))
const _8c669dc0 = () => interopDefault(import('../pages/trade-landing/dilmah-tea-for-fine-food-and-gourmet-stores.vue' /* webpackChunkName: "pages/trade-landing/dilmah-tea-for-fine-food-and-gourmet-stores" */))
const _06df6448 = () => interopDefault(import('../pages/trade-landing/dilmah-tea-for-high-quality-casual-dining.vue' /* webpackChunkName: "pages/trade-landing/dilmah-tea-for-high-quality-casual-dining" */))
const _1758c752 = () => interopDefault(import('../pages/trade-landing/dilmah-tea-for-premium-retailers.vue' /* webpackChunkName: "pages/trade-landing/dilmah-tea-for-premium-retailers" */))
const _09ad254e = () => interopDefault(import('../pages/our-products/gift-of-teas/teawares.vue' /* webpackChunkName: "pages/our-products/gift-of-teas/teawares" */))
const _e7b97634 = () => interopDefault(import('../pages/our-products/our-teas/tea-by-flavour/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-by-flavour/index" */))
const _2550a278 = () => interopDefault(import('../pages/our-products/our-teas/tea-by-mood/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-by-mood/index" */))
const _b5c52732 = () => interopDefault(import('../pages/our-products/our-teas/tea-formats/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-formats/index" */))
const _0899e381 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/index" */))
const _be078d78 = () => interopDefault(import('../pages/our-products/our-teas/tea-types/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-types/index" */))
const _943cdf40 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/70-years-in-tea.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/70-years-in-tea" */))
const _746851b4 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/letters-to-mr-dilmah.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/letters-to-mr-dilmah" */))
const _00e372f7 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/tea-inspiration-books.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/tea-inspiration-books" */))
const _0076a3d6 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/tea-inspired-commemorative-teas.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/tea-inspired-commemorative-teas" */))
const _1ff65b16 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/tea-inspired-letters-to-mr-dilmah.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/tea-inspired-letters-to-mr-dilmah" */))
const _33890cba = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/tea-inspired-video-wishes.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/tea-inspired-video-wishes" */))
const _05c9a5e2 = () => interopDefault(import('../pages/tea-inspired/years-of-tea-inspiration/teamakers-philosophy.vue' /* webpackChunkName: "pages/tea-inspired/years-of-tea-inspiration/teamakers-philosophy" */))
const _3063ea83 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/arana-herbal.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/arana-herbal" */))
const _7d754c30 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/ceylon-golden-pekoe.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/ceylon-golden-pekoe" */))
const _1a63b1ee = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/ceylon-green-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/ceylon-green-tea" */))
const _cf02de44 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/commerative-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/commerative-tea" */))
const _7cb6c20c = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-ceylon-gold.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-ceylon-gold" */))
const _ed16e9d4 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-ceylon-iced-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-ceylon-iced-tea" */))
const _da744404 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-exceptional-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-exceptional-tea" */))
const _41d522fb = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-gourmet-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-gourmet-tea" */))
const _54a1cb2a = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-inspiration.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-inspiration" */))
const _0401a38a = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/index" */))
const _596d6c37 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-organics.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-organics" */))
const _27db375c = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-realtea-cordial.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-realtea-cordial" */))
const _750565b3 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-silver-jubilee-gourmet.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-silver-jubilee-gourmet" */))
const _280cc06b = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/index" */))
const _ca3193ba = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/index" */))
const _71f44731 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/fun-flavoured.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/fun-flavoured" */))
const _100b645e = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/natural-green-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/natural-green-tea" */))
const _25a32bee = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/premium-ceylon-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/premium-ceylon-tea" */))
const _93301ed4 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/product-details/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/product-details/index" */))
const _4efb9b07 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/index" */))
const _7fa3a65c = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/tea-makers-reserve.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/tea-makers-reserve" */))
const _fb627454 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/index" */))
const _56fe25f0 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/vivid-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/vivid-tea" */))
const _45ac961e = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/glass-bottles.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/glass-bottles" */))
const _248aba14 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/tetra-packs.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/tetra-packs" */))
const _340f90e5 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/for-every-mood.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/for-every-mood" */))
const _def683be = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-infusions.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-infusions" */))
const _e2afba88 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients/index" */))
const _47fe1074 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/estate-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/estate-tea" */))
const _059a8924 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/region-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/region-tea" */))
const _2b0d1650 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/regions.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/regions" */))
const _f1bb2b98 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/brewing-guIdeline.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/brewing-guIdeline" */))
const _179ecfd4 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/elixir-recipes.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/elixir-recipes" */))
const _55ef73df = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/our-elixir.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/our-elixir" */))
const _6454cf20 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/real-tea.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/real-tea" */))
const _7eb82f14 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/gourmet-teas.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/gourmet-teas" */))
const _58273a63 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/rare-teas.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/rare-teas" */))
const _05fa953a = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush/index.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush/index" */))
const _6296eb11 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/about.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/about" */))
const _57802c20 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/our-teas.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/our-teas" */))
const _0520597e = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/tea-location.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/teamakers-private-reserve/tea-location" */))
const _f3d0b7b8 = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients/_id.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients/_id" */))
const _511696ea = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush/_id.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush/_id" */))
const _59a3ef2e = () => interopDefault(import('../pages/our-products/our-teas/tea-ranges/product-details/_name.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-ranges/product-details/_name" */))
const _13a23364 = () => interopDefault(import('../pages/our-products/our-teas/tea-by-flavour/_flavour.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-by-flavour/_flavour" */))
const _489e4b7c = () => interopDefault(import('../pages/our-products/our-teas/tea-by-mood/_mood.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-by-mood/_mood" */))
const _167feb6b = () => interopDefault(import('../pages/our-products/our-teas/tea-formats/_format.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-formats/_format" */))
const _a39945ea = () => interopDefault(import('../pages/our-products/our-teas/tea-types/_type.vue' /* webpackChunkName: "pages/our-products/our-teas/tea-types/_type" */))
const _857e7250 = () => interopDefault(import('../pages/all-about-tea/dilmah-podcasts/_id/index.vue' /* webpackChunkName: "pages/all-about-tea/dilmah-podcasts/_id/index" */))
const _1f2990fc = () => interopDefault(import('../pages/all-about-tea/tea-inspired-e-books/_id.vue' /* webpackChunkName: "pages/all-about-tea/tea-inspired-e-books/_id" */))
const _29c75e1a = () => interopDefault(import('../pages/dilmah-family/our-tea-garden/_id.vue' /* webpackChunkName: "pages/dilmah-family/our-tea-garden/_id" */))
const _97a845fe = () => interopDefault(import('../pages/media/dilmah-in-press/_id.vue' /* webpackChunkName: "pages/media/dilmah-in-press/_id" */))
const _f77102b6 = () => interopDefault(import('../pages/media/dilmah-tea-blog/_id.vue' /* webpackChunkName: "pages/media/dilmah-tea-blog/_id" */))
const _1dfc6539 = () => interopDefault(import('../pages/media/events/_id.vue' /* webpackChunkName: "pages/media/events/_id" */))
const _7cf990d0 = () => interopDefault(import('../pages/media/images/_id.vue' /* webpackChunkName: "pages/media/images/_id" */))
const _d56b6f9a = () => interopDefault(import('../pages/media/news/_id.vue' /* webpackChunkName: "pages/media/news/_id" */))
const _7371cf92 = () => interopDefault(import('../pages/our-products/gift-of-teas/_id.vue' /* webpackChunkName: "pages/our-products/gift-of-teas/_id" */))
const _7ab716c8 = () => interopDefault(import('../pages/our-products/product-details-tea-format/_name.vue' /* webpackChunkName: "pages/our-products/product-details-tea-format/_name" */))
const _8c6a6784 = () => interopDefault(import('../pages/our-products/tea-accessories/_id.vue' /* webpackChunkName: "pages/our-products/tea-accessories/_id" */))
const _2aff110e = () => interopDefault(import('../pages/tea-inspired/artists/_id.vue' /* webpackChunkName: "pages/tea-inspired/artists/_id" */))
const _275036ff = () => interopDefault(import('../pages/tea-inspired/mediaphotographers/_id.vue' /* webpackChunkName: "pages/tea-inspired/mediaphotographers/_id" */))
const _5e509c34 = () => interopDefault(import('../pages/tea-inspired/prealbums/_id.vue' /* webpackChunkName: "pages/tea-inspired/prealbums/_id" */))
const _78162a98 = () => interopDefault(import('../pages/tea-inspired/tea-inspiredme/_id.vue' /* webpackChunkName: "pages/tea-inspired/tea-inspiredme/_id" */))
const _38f741f8 = () => interopDefault(import('../pages/all-about-tea/dilmah-podcasts/_id/_item.vue' /* webpackChunkName: "pages/all-about-tea/dilmah-podcasts/_id/_item" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _67ed178c,
    name: "blog"
  }, {
    path: "/careers",
    component: _58dee93b,
    name: "careers"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/dilmah-online",
    component: _5d64a3b9,
    name: "dilmah-online"
  }, {
    path: "/global-search-results",
    component: _f4f53d7e,
    name: "global-search-results"
  }, {
    path: "/media",
    component: _72c0d926,
    name: "media"
  }, {
    path: "/tea-jornal",
    component: _573e6d73,
    name: "tea-jornal"
  }, {
    path: "/trade-landing",
    component: _42e26892,
    name: "trade-landing"
  }, {
    path: "/all-about-tea/dilmah-podcasts",
    component: _2accaa6d,
    name: "all-about-tea-dilmah-podcasts"
  }, {
    path: "/all-about-tea/facts-of-tea",
    component: _7adb1995,
    name: "all-about-tea-facts-of-tea"
  }, {
    path: "/all-about-tea/how-to-brew-a-perfect-cup-of-tea",
    component: _2fa37720,
    name: "all-about-tea-how-to-brew-a-perfect-cup-of-tea"
  }, {
    path: "/all-about-tea/infographic",
    component: _56e42995,
    name: "all-about-tea-infographic"
  }, {
    path: "/all-about-tea/school-of-tea",
    component: _6d5a5524,
    name: "all-about-tea-school-of-tea"
  }, {
    path: "/all-about-tea/school-of-tea-e-learning",
    component: _4214c016,
    name: "all-about-tea-school-of-tea-e-learning"
  }, {
    path: "/all-about-tea/tea-and-health",
    component: _70159fa4,
    name: "all-about-tea-tea-and-health"
  }, {
    path: "/all-about-tea/tea-inspired-e-books",
    component: _2af23994,
    name: "all-about-tea-tea-inspired-e-books"
  }, {
    path: "/dilmah-family/awards",
    component: _1b3578cb,
    name: "dilmah-family-awards"
  }, {
    path: "/dilmah-family/dilmah-story",
    component: _08e0d46a,
    name: "dilmah-family-dilmah-story"
  }, {
    path: "/dilmah-family/founders-message",
    component: _00c184b3,
    name: "dilmah-family-founders-message"
  }, {
    path: "/dilmah-family/leisure-hospitality",
    component: _ed5dfe72,
    name: "dilmah-family-leisure-hospitality"
  }, {
    path: "/dilmah-family/our-tea-garden",
    component: _7596506a,
    name: "dilmah-family-our-tea-garden"
  }, {
    path: "/dilmah-family/our-values",
    component: _d297c650,
    name: "dilmah-family-our-values"
  }, {
    path: "/dilmah-family/quality-certifications",
    component: _9e6823c8,
    name: "dilmah-family-quality-certifications"
  }, {
    path: "/dilmah-family/testimonials",
    component: _42fe9609,
    name: "dilmah-family-testimonials"
  }, {
    path: "/dilmah-family/timeline",
    component: _17915296,
    name: "dilmah-family-timeline"
  }, {
    path: "/dilmah-family/what-we-do",
    component: _a6924a94,
    name: "dilmah-family-what-we-do"
  }, {
    path: "/media/audios",
    component: _40cf4ad2,
    name: "media-audios"
  }, {
    path: "/media/dilmah-in-press",
    component: _eedccf4e,
    name: "media-dilmah-in-press"
  }, {
    path: "/media/dilmah-tea-blog",
    component: _7f693e06,
    name: "media-dilmah-tea-blog"
  }, {
    path: "/media/events",
    component: _7f3e1ade,
    name: "media-events"
  }, {
    path: "/media/images",
    component: _c506ada0,
    name: "media-images"
  }, {
    path: "/media/news",
    component: _c87c01ea,
    name: "media-news"
  }, {
    path: "/media/videos",
    component: _f7f11d66,
    name: "media-videos"
  }, {
    path: "/not-use-pages/ebooks",
    component: _044dd13a,
    name: "not-use-pages-ebooks"
  }, {
    path: "/not-use-pages/ginger",
    component: _3d3ceb46,
    name: "not-use-pages-ginger"
  }, {
    path: "/not-use-pages/human-rights-policy",
    component: _344db17a,
    name: "not-use-pages-human-rights-policy"
  }, {
    path: "/not-use-pages/infusions-selections",
    component: _2175087f,
    name: "not-use-pages-infusions-selections"
  }, {
    path: "/not-use-pages/knowledge-and-training-dropdown",
    component: _181159cc,
    name: "not-use-pages-knowledge-and-training-dropdown"
  }, {
    path: "/not-use-pages/not-use-tea-inspired",
    component: _0d427788,
    name: "not-use-pages-not-use-tea-inspired"
  }, {
    path: "/not-use-pages/our-infusions",
    component: _587a42b8,
    name: "not-use-pages-our-infusions"
  }, {
    path: "/not-use-pages/our-purpose-dropdown",
    component: _9d74c154,
    name: "not-use-pages-our-purpose-dropdown"
  }, {
    path: "/not-use-pages/premium-ceylon-tea-selection",
    component: _bfe4383e,
    name: "not-use-pages-premium-ceylon-tea-selection"
  }, {
    path: "/not-use-pages/product-information",
    component: _c32d876e,
    name: "not-use-pages-product-information"
  }, {
    path: "/not-use-pages/real-tea-cordial-selection",
    component: _a8b95f44,
    name: "not-use-pages-real-tea-cordial-selection"
  }, {
    path: "/not-use-pages/seasonal-flush",
    component: _3dcebccc,
    name: "not-use-pages-seasonal-flush"
  }, {
    path: "/not-use-pages/seasonal-flush-product-detail",
    component: _ab2a4c9c,
    name: "not-use-pages-seasonal-flush-product-detail"
  }, {
    path: "/not-use-pages/t-series-designer-gourmet-tea",
    component: _1eeef710,
    name: "not-use-pages-t-series-designer-gourmet-tea"
  }, {
    path: "/our-products/gift-of-teas",
    component: _fe6a43e2,
    name: "our-products-gift-of-teas"
  }, {
    path: "/our-products/our-teas-recommendation",
    component: _bbe9f7fc,
    name: "our-products-our-teas-recommendation"
  }, {
    path: "/our-products/tea-accessories",
    component: _bb9ca754,
    name: "our-products-tea-accessories"
  }, {
    path: "/our-products/where-to-buy",
    component: _951c5126,
    name: "our-products-where-to-buy"
  }, {
    path: "/our-purpose/dilmah-conservation",
    component: _10247c5e,
    name: "our-purpose-dilmah-conservation"
  }, {
    path: "/our-purpose/mjf-Foundation",
    component: _4933ae53,
    name: "our-purpose-mjf-Foundation"
  }, {
    path: "/our-purpose/sustainability",
    component: _949b9d7a,
    name: "our-purpose-sustainability"
  }, {
    path: "/privacy-and-terms/dilmah-anti-bribary-policy",
    component: _0cab18d0,
    name: "privacy-and-terms-dilmah-anti-bribary-policy"
  }, {
    path: "/privacy-and-terms/dilmah-business-ethics-policy",
    component: _4d8dadd6,
    name: "privacy-and-terms-dilmah-business-ethics-policy"
  }, {
    path: "/privacy-and-terms/dilmah-human-rights-policy",
    component: _3cb51a35,
    name: "privacy-and-terms-dilmah-human-rights-policy"
  }, {
    path: "/privacy-and-terms/privacy-and-cookie-policy",
    component: _21666383,
    name: "privacy-and-terms-privacy-and-cookie-policy"
  }, {
    path: "/privacy-and-terms/terms-conditions",
    component: _1513094b,
    name: "privacy-and-terms-terms-conditions"
  }, {
    path: "/social-share/fb",
    component: _51ed7252,
    name: "social-share-fb"
  }, {
    path: "/tea-inspired/artists",
    component: _7c2974d1,
    name: "tea-inspired-artists"
  }, {
    path: "/tea-inspired/chefs-and-the-tea-maker",
    component: _e15eab52,
    name: "tea-inspired-chefs-and-the-tea-maker"
  }, {
    path: "/tea-inspired/dilmah-hospitality-and-food-service",
    component: _5d574ad2,
    name: "tea-inspired-dilmah-hospitality-and-food-service"
  }, {
    path: "/tea-inspired/dilmah-real-high-tea",
    component: _e9d365fc,
    name: "tea-inspired-dilmah-real-high-tea"
  }, {
    path: "/tea-inspired/dilmah-tea-inspiration-for-the-21st-century",
    component: _4b5649ee,
    name: "tea-inspired-dilmah-tea-inspiration-for-the-21st-century"
  }, {
    path: "/tea-inspired/mediaphotographers",
    component: _77f12a52,
    name: "tea-inspired-mediaphotographers"
  }, {
    path: "/tea-inspired/mixologist-and-the-tea-maker",
    component: _cad47822,
    name: "tea-inspired-mixologist-and-the-tea-maker"
  }, {
    path: "/tea-inspired/prealbums",
    component: _3c8356cc,
    name: "tea-inspired-prealbums"
  }, {
    path: "/tea-inspired/tea-infive-senses",
    component: _24872a00,
    name: "tea-inspired-tea-infive-senses"
  }, {
    path: "/tea-inspired/tea-inspiredme",
    component: _09c247a0,
    name: "tea-inspired-tea-inspiredme"
  }, {
    path: "/tea-inspired/tea-lounge",
    component: _6c396245,
    name: "tea-inspired-tea-lounge"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration",
    component: _301c1c3f,
    name: "tea-inspired-years-of-tea-inspiration"
  }, {
    path: "/trade-landing/dilmah-tea-for-fine-dining",
    component: _6ee7a6f6,
    name: "trade-landing-dilmah-tea-for-fine-dining"
  }, {
    path: "/trade-landing/dilmah-tea-for-fine-food-and-gourmet-stores",
    component: _8c669dc0,
    name: "trade-landing-dilmah-tea-for-fine-food-and-gourmet-stores"
  }, {
    path: "/trade-landing/dilmah-tea-for-high-quality-casual-dining",
    component: _06df6448,
    name: "trade-landing-dilmah-tea-for-high-quality-casual-dining"
  }, {
    path: "/trade-landing/dilmah-tea-for-premium-retailers",
    component: _1758c752,
    name: "trade-landing-dilmah-tea-for-premium-retailers"
  }, {
    path: "/our-products/gift-of-teas/teawares",
    component: _09ad254e,
    name: "our-products-gift-of-teas-teawares"
  }, {
    path: "/our-products/our-teas/tea-by-flavour",
    component: _e7b97634,
    name: "our-products-our-teas-tea-by-flavour"
  }, {
    path: "/our-products/our-teas/tea-by-mood",
    component: _2550a278,
    name: "our-products-our-teas-tea-by-mood"
  }, {
    path: "/our-products/our-teas/tea-formats",
    component: _b5c52732,
    name: "our-products-our-teas-tea-formats"
  }, {
    path: "/our-products/our-teas/tea-ranges",
    component: _0899e381,
    name: "our-products-our-teas-tea-ranges"
  }, {
    path: "/our-products/our-teas/tea-types",
    component: _be078d78,
    name: "our-products-our-teas-tea-types"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/70-years-in-tea",
    component: _943cdf40,
    name: "tea-inspired-years-of-tea-inspiration-70-years-in-tea"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/letters-to-mr-dilmah",
    component: _746851b4,
    name: "tea-inspired-years-of-tea-inspiration-letters-to-mr-dilmah"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/tea-inspiration-books",
    component: _00e372f7,
    name: "tea-inspired-years-of-tea-inspiration-tea-inspiration-books"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/tea-inspired-commemorative-teas",
    component: _0076a3d6,
    name: "tea-inspired-years-of-tea-inspiration-tea-inspired-commemorative-teas"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/tea-inspired-letters-to-mr-dilmah",
    component: _1ff65b16,
    name: "tea-inspired-years-of-tea-inspiration-tea-inspired-letters-to-mr-dilmah"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/tea-inspired-video-wishes",
    component: _33890cba,
    name: "tea-inspired-years-of-tea-inspiration-tea-inspired-video-wishes"
  }, {
    path: "/tea-inspired/years-of-tea-inspiration/teamakers-philosophy",
    component: _05c9a5e2,
    name: "tea-inspired-years-of-tea-inspiration-teamakers-philosophy"
  }, {
    path: "/our-products/our-teas/tea-ranges/arana-herbal",
    component: _3063ea83,
    name: "our-products-our-teas-tea-ranges-arana-herbal"
  }, {
    path: "/our-products/our-teas/tea-ranges/ceylon-golden-pekoe",
    component: _7d754c30,
    name: "our-products-our-teas-tea-ranges-ceylon-golden-pekoe"
  }, {
    path: "/our-products/our-teas/tea-ranges/ceylon-green-tea",
    component: _1a63b1ee,
    name: "our-products-our-teas-tea-ranges-ceylon-green-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/commerative-tea",
    component: _cf02de44,
    name: "our-products-our-teas-tea-ranges-commerative-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-ceylon-gold",
    component: _7cb6c20c,
    name: "our-products-our-teas-tea-ranges-dilmah-ceylon-gold"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-ceylon-iced-tea",
    component: _ed16e9d4,
    name: "our-products-our-teas-tea-ranges-dilmah-ceylon-iced-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-exceptional-tea",
    component: _da744404,
    name: "our-products-our-teas-tea-ranges-dilmah-exceptional-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-gourmet-tea",
    component: _41d522fb,
    name: "our-products-our-teas-tea-ranges-dilmah-gourmet-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-inspiration",
    component: _54a1cb2a,
    name: "our-products-our-teas-tea-ranges-dilmah-inspiration"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-natural-infusions",
    component: _0401a38a,
    name: "our-products-our-teas-tea-ranges-dilmah-natural-infusions"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-organics",
    component: _596d6c37,
    name: "our-products-our-teas-tea-ranges-dilmah-organics"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-realtea-cordial",
    component: _27db375c,
    name: "our-products-our-teas-tea-ranges-dilmah-realtea-cordial"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-silver-jubilee-gourmet",
    component: _750565b3,
    name: "our-products-our-teas-tea-ranges-dilmah-silver-jubilee-gourmet"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea",
    component: _280cc06b,
    name: "our-products-our-teas-tea-ranges-dilmah-watte-boutique-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea",
    component: _ca3193ba,
    name: "our-products-our-teas-tea-ranges-elixir-of-ceylon-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/fun-flavoured",
    component: _71f44731,
    name: "our-products-our-teas-tea-ranges-fun-flavoured"
  }, {
    path: "/our-products/our-teas/tea-ranges/natural-green-tea",
    component: _100b645e,
    name: "our-products-our-teas-tea-ranges-natural-green-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/premium-ceylon-tea",
    component: _25a32bee,
    name: "our-products-our-teas-tea-ranges-premium-ceylon-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/product-details",
    component: _93301ed4,
    name: "our-products-our-teas-tea-ranges-product-details"
  }, {
    path: "/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea",
    component: _4efb9b07,
    name: "our-products-our-teas-tea-ranges-t-series-designer-gourmet-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/tea-makers-reserve",
    component: _7fa3a65c,
    name: "our-products-our-teas-tea-ranges-tea-makers-reserve"
  }, {
    path: "/our-products/our-teas/tea-ranges/teamakers-private-reserve",
    component: _fb627454,
    name: "our-products-our-teas-tea-ranges-teamakers-private-reserve"
  }, {
    path: "/our-products/our-teas/tea-ranges/vivid-tea",
    component: _56fe25f0,
    name: "our-products-our-teas-tea-ranges-vivid-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/glass-bottles",
    component: _45ac961e,
    name: "our-products-our-teas-tea-ranges-dilmah-ceylon-ice-tea-glass-bottles"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-ceylon-ice-tea/tetra-packs",
    component: _248aba14,
    name: "our-products-our-teas-tea-ranges-dilmah-ceylon-ice-tea-tetra-packs"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-natural-infusions/for-every-mood",
    component: _340f90e5,
    name: "our-products-our-teas-tea-ranges-dilmah-natural-infusions-for-every-mood"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-infusions",
    component: _def683be,
    name: "our-products-our-teas-tea-ranges-dilmah-natural-infusions-our-infusions"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients",
    component: _e2afba88,
    name: "our-products-our-teas-tea-ranges-dilmah-natural-infusions-our-ingredients"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/estate-tea",
    component: _47fe1074,
    name: "our-products-our-teas-tea-ranges-dilmah-watte-boutique-tea-estate-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/region-tea",
    component: _059a8924,
    name: "our-products-our-teas-tea-ranges-dilmah-watte-boutique-tea-region-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-watte-boutique-tea/regions",
    component: _2b0d1650,
    name: "our-products-our-teas-tea-ranges-dilmah-watte-boutique-tea-regions"
  }, {
    path: "/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/brewing-guIdeline",
    component: _f1bb2b98,
    name: "our-products-our-teas-tea-ranges-elixir-of-ceylon-tea-brewing-guIdeline"
  }, {
    path: "/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/elixir-recipes",
    component: _179ecfd4,
    name: "our-products-our-teas-tea-ranges-elixir-of-ceylon-tea-elixir-recipes"
  }, {
    path: "/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/our-elixir",
    component: _55ef73df,
    name: "our-products-our-teas-tea-ranges-elixir-of-ceylon-tea-our-elixir"
  }, {
    path: "/our-products/our-teas/tea-ranges/elixir-of-ceylon-tea/real-tea",
    component: _6454cf20,
    name: "our-products-our-teas-tea-ranges-elixir-of-ceylon-tea-real-tea"
  }, {
    path: "/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/gourmet-teas",
    component: _7eb82f14,
    name: "our-products-our-teas-tea-ranges-t-series-designer-gourmet-tea-gourmet-teas"
  }, {
    path: "/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/rare-teas",
    component: _58273a63,
    name: "our-products-our-teas-tea-ranges-t-series-designer-gourmet-tea-rare-teas"
  }, {
    path: "/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush",
    component: _05fa953a,
    name: "our-products-our-teas-tea-ranges-t-series-designer-gourmet-tea-seasonal-flush"
  }, {
    path: "/our-products/our-teas/tea-ranges/teamakers-private-reserve/about",
    component: _6296eb11,
    name: "our-products-our-teas-tea-ranges-teamakers-private-reserve-about"
  }, {
    path: "/our-products/our-teas/tea-ranges/teamakers-private-reserve/our-teas",
    component: _57802c20,
    name: "our-products-our-teas-tea-ranges-teamakers-private-reserve-our-teas"
  }, {
    path: "/our-products/our-teas/tea-ranges/teamakers-private-reserve/tea-location",
    component: _0520597e,
    name: "our-products-our-teas-tea-ranges-teamakers-private-reserve-tea-location"
  }, {
    path: "/our-products/our-teas/tea-ranges/dilmah-natural-infusions/our-ingredients/:id?",
    component: _f3d0b7b8,
    name: "our-products-our-teas-tea-ranges-dilmah-natural-infusions-our-ingredients-id"
  }, {
    path: "/our-products/our-teas/tea-ranges/t-series-designer-gourmet-tea/seasonal-flush/:id?",
    component: _511696ea,
    name: "our-products-our-teas-tea-ranges-t-series-designer-gourmet-tea-seasonal-flush-id"
  }, {
    path: "/our-products/our-teas/tea-ranges/product-details/:name?",
    component: _59a3ef2e,
    name: "our-products-our-teas-tea-ranges-product-details-name"
  }, {
    path: "/our-products/our-teas/tea-by-flavour/:flavour?",
    component: _13a23364,
    name: "our-products-our-teas-tea-by-flavour-flavour"
  }, {
    path: "/our-products/our-teas/tea-by-mood/:mood?",
    component: _489e4b7c,
    name: "our-products-our-teas-tea-by-mood-mood"
  }, {
    path: "/our-products/our-teas/tea-formats/:format?",
    component: _167feb6b,
    name: "our-products-our-teas-tea-formats-format"
  }, {
    path: "/our-products/our-teas/tea-types/:type?",
    component: _a39945ea,
    name: "our-products-our-teas-tea-types-type"
  }, {
    path: "/all-about-tea/dilmah-podcasts/:id",
    component: _857e7250,
    name: "all-about-tea-dilmah-podcasts-id"
  }, {
    path: "/all-about-tea/tea-inspired-e-books/:id?",
    component: _1f2990fc,
    name: "all-about-tea-tea-inspired-e-books-id"
  }, {
    path: "/dilmah-family/our-tea-garden/:id?",
    component: _29c75e1a,
    name: "dilmah-family-our-tea-garden-id"
  }, {
    path: "/media/dilmah-in-press/:id?",
    component: _97a845fe,
    name: "media-dilmah-in-press-id"
  }, {
    path: "/media/dilmah-tea-blog/:id?",
    component: _f77102b6,
    name: "media-dilmah-tea-blog-id"
  }, {
    path: "/media/events/:id",
    component: _1dfc6539,
    name: "media-events-id"
  }, {
    path: "/media/images/:id",
    component: _7cf990d0,
    name: "media-images-id"
  }, {
    path: "/media/news/:id",
    component: _d56b6f9a,
    name: "media-news-id"
  }, {
    path: "/our-products/gift-of-teas/:id?",
    component: _7371cf92,
    name: "our-products-gift-of-teas-id"
  }, {
    path: "/our-products/product-details-tea-format/:name?",
    component: _7ab716c8,
    name: "our-products-product-details-tea-format-name"
  }, {
    path: "/our-products/tea-accessories/:id?",
    component: _8c6a6784,
    name: "our-products-tea-accessories-id"
  }, {
    path: "/tea-inspired/artists/:id?",
    component: _2aff110e,
    name: "tea-inspired-artists-id"
  }, {
    path: "/tea-inspired/mediaphotographers/:id?",
    component: _275036ff,
    name: "tea-inspired-mediaphotographers-id"
  }, {
    path: "/tea-inspired/prealbums/:id?",
    component: _5e509c34,
    name: "tea-inspired-prealbums-id"
  }, {
    path: "/tea-inspired/tea-inspiredme/:id?",
    component: _78162a98,
    name: "tea-inspired-tea-inspiredme-id"
  }, {
    path: "/all-about-tea/dilmah-podcasts/:id?/:item?",
    component: _38f741f8,
    name: "all-about-tea-dilmah-podcasts-id-item"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
