import Vue from "vue";
import { mapState } from "vuex";

import $ from "jquery";
import jQueryBridget from "jquery-bridget";
import Masonry from "masonry-layout";
window.$ = $;

Vue.mixin({
  data() {
    return {};
  },

  mounted() {
    jQueryBridget("masonry", Masonry, $);
  },
  methods: {
    // stripHtml(html) {
    //     let tmp = document.createElement("DIV");
    //     tmp.innerHTML = html;
    //     return tmp.textContent || tmp.innerText || "";
    // }
  },
  filters: {
    trimText: (val, limit) => {
      if (typeof val != "undefined") {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = val;
        let text = tmp.textContent || tmp.innerText || "";
        if (text.length < limit) {
          return text;
        } else {
          return text.substring(0, limit) + "...";
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    toRoman(num, lower = false) {
      if (typeof num !== "number") return false;

      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman_num = "",
        i = 3;
      while (i--) roman_num = (key[+digits.pop() + i * 10] || "") + roman_num;
      let data = Array(+digits.join("") + 1).join("M") + roman_num;
      if (lower) {
        return data.toLowerCase();
      } else {
        return data;
      }
    },
  },
});
