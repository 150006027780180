export const state = () => ({
  dilmahHospitality: null,
  teaInspirational: null,
  realHightea: null,
  cafeandTea: null,
  mixologist: null,
  fivesense: null,
  mediaimages: null,
  teainspiredimages: null,
  teaLounges: null,
  inspiredHome: null,
  teamakersPhilosophy: [],
});

export const mutations = {
  SET_DILMAH_HOSPITALITY(state, data) {
    state.dilmahHospitality = data;
  },
  SET_TEA_INSPIRATIONAL(state, data) {
    state.teaInspirational = data;
  },
  SET_REAL_HIGHTEAPAGES(state, data) {
    state.realHightea = data;
  },
  SET_CAFE_ANDTEAMAKERSPAGES(state, data) {
    state.cafeandTea = data;
  },
  SET_MIXOLOGIST_ANDTEAMAKERSPAGES(state, data) {
    state.mixologist = data;
  },
  SET_TEA_INFIVESENSE(state, data) {
    state.fivesense = data;
  },

  SET_MEDIA_IMAGESPAGES(state, data) {
    state.mediaimages = data;
  },

  SET_TEA_INSPIREDIMAGES(state, data) {
    state.teainspiredimages = data;
  },
  SET_TEA_LOUNGE(state, data) {
    state.teaLounges = data;
  },
  SET_TEAMAKERS_PHILOSOPHY(state, data) {
    state.teamakersPhilosophy = data.teamakersPhilosophyPages[0].page;
  },
  SET_INSPIRED_HOME(state, data) {
    state.inspiredHome = data;
  },
};

export const actions = {
  async gethospitalityPages({ commit, rootState }) {
    const graphqlq = {
      query: `{
          hospitalityPages(where: { country: "${rootState.currentCountry}" }) {
            id
            country
            page {
              title {
                ${rootState.currentLanguage}
              }
              invitation {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              mid_title {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              mid_quote {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              end_quote_with_link {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              sliders {
                slider_has_video
                video_url
                image_url
                header {
                  ${rootState.currentLanguage}
                }
              }
              values {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              hospitality {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
                
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
    }
        `,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_DILMAH_HOSPITALITY", response.data.hospitalityPages);
    });
  },

  async getteaInspirational({ commit, rootState }) {
    const graphqlq = {
      query: `{
          teaInspirationPages(where: { country: "${rootState.currentCountry}" }) {
            id
            country
            page {
              title {
                ${rootState.currentLanguage}
              }
              content_1 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              content_2 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
                has_video
                video_url
              }
        
              sliders {
                slider_has_video
                video_url
                image_url
                header {
                  ${rootState.currentLanguage}
                }
              }
              show_us_link

              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
    }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit(
        "SET_TEA_INSPIRATIONAL",
        response.data.teaInspirationPages[0].page
      );
    });
  },

  async getrealHighteaPages({ commit, rootState }) {
    const graphqlq = {
      query: `{
      dilmahRealHighTeaPages(where: { country: "${rootState.currentCountry}" }) {
        id
        country
        page {
          title {
            ${rootState.currentLanguage}
          }
          content_1 {
            has_header
            has_link
            link
            header {
              ${rootState.currentLanguage}
            }
            content {
              ${rootState.currentLanguage}
            }
            link_text {
              ${rootState.currentLanguage}
            }
            image_url
          }
          content_2 {
            has_header
            has_link
            link
            header {
              ${rootState.currentLanguage}
            }
            content {
              ${rootState.currentLanguage}
            }
            link_text {
              ${rootState.currentLanguage}
            }
            image_url
            has_video
            video_url
          }
          sliders {
            slider_has_video
            video_url
            image_url
            header {
              ${rootState.currentLanguage}
            }
          }
          seo{     
            id 
            meta_title
            meta_description 
            keywords
            share_image_alt
            share_image_url
            prevent_indexing 
        }
        }
      }
  }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit(
        "SET_REAL_HIGHTEAPAGES",
        response.data.dilmahRealHighTeaPages[0].page
      );
    });
  },

  async getcafeandTeamakersPages({ commit, rootState }) {
    const graphqlq = {
      query: `{
          cafeAndTheTeaMakerPages(where: { country: "${rootState.currentCountry}" }) {
              id
              country
              page {
                title {
                  ${rootState.currentLanguage}
                }
                content_1 {
                  has_header
                  has_link
                  link
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    ${rootState.currentLanguage}
                  }
                  link_text {
                    ${rootState.currentLanguage}
                  }
                  image_url
                }
                content_2 {
                  has_header
                  has_link
                  link
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    ${rootState.currentLanguage}
                  }
                  link_text {
                    ${rootState.currentLanguage}
                  }
                  image_url
                  has_video
                  video_url
                }
          
                sliders {
                  slider_has_video
                  video_url
                  image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                }
                seo{     
                  id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
              }
              }
            }
      }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit(
        "SET_CAFE_ANDTEAMAKERSPAGES",
        response.data.cafeAndTheTeaMakerPages
      );
    });
  },

  async getmixologisteandTeamakersPages({ commit, rootState }) {
    const graphqlq = {
      query: `{
              mixologistAndTheTeaMakerPages(where: { country: "${rootState.currentCountry}" }) {
                  id
                  country
                  page {
                    title {
                      ${rootState.currentLanguage}
                    }
                    content_1 {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                    content_2 {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                      has_video
                      video_url
                    }
              
                    sliders {
                      slider_has_video
                      video_url
                      image_url
                      header {
                        ${rootState.currentLanguage}
                      }
                    }
                    seo{     
                      id 
                      meta_title
                      meta_description 
                      keywords
                      share_image_alt
                      share_image_url
                      prevent_indexing 
                  }
                  }
                }
        }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit(
        "SET_MIXOLOGIST_ANDTEAMAKERSPAGES",
        response.data.mixologistAndTheTeaMakerPages
      );
    });
  },

  async getteaInFiveSense({ commit, rootState }) {
    const graphqlq = {
      query: `{
              teaInAllSensesPages(where: { country: "${rootState.currentCountry}" }) {
                  id
                  country
                  page {
                    contents {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                    sliders {
                      slider_has_video
                      video_url
                      image_url
                      header {
                        ${rootState.currentLanguage}
                      }
                    }
                    seo{     
                      id 
                      meta_title
                      meta_description 
                      keywords
                      share_image_alt
                      share_image_url
                      prevent_indexing 
                  }
                  }
                }
              
        }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_TEA_INFIVESENSE", response.data.teaInAllSensesPages);
    });
  },
  async getteaLoungePages({ commit, rootState }) {
    const graphqlq = {
      query: `{
              teaLoungePages(where: { country: "${rootState.currentCountry}" }) {
                id
                country
                page {
                  title {
                    ${rootState.currentLanguage}
                  }
                header_image_url
                content_1 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                quote{
                  ${rootState.currentLanguage}
                }
                author{
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
                }
                content_2 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                quote{
                  ${rootState.currentLanguage}
                }
                author{
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
                has_video
                video_url
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
      }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_TEA_LOUNGE", response.data.teaLoungePages);
    });
  },

  async getMediaImagespages({ commit, rootState }) {
    const graphqlq = {
      query: `{
        mediaImagesPages(where: { country: "${rootState.currentCountry}" }) {
          country
          page {
            sliders {
              slider_has_video
              video_url
              image_url
              header {
                ${rootState.currentLanguage}
              }
            }
          }
        }
      }

    `,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_MEDIA_IMAGESPAGES", response.data.mediaImagesPages);
    });
  },

  async getTeaInspiredImages({ commit, rootState }) {
    const graphqlq = {
      query: `{
        teaInspiredImagePages(where: { country: "${rootState.currentCountry}" }) {
          country
          page {
            header_image_url
            title{
              ${rootState.currentLanguage}
            }
            photographers{
              id
              image_url
              name{
                ${rootState.currentLanguage}
              }
              description{
                ${rootState.currentLanguage}
              }
              images{
                image_url
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            albums{
              id
              cover_image_url
              title{
                ${rootState.currentLanguage}
              }
              short_description{
                ${rootState.currentLanguage}
              }
              long_description{
                ${rootState.currentLanguage}
              }
              images{
                image_url
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            tea_inspired_me{
              id
              cover_image_url
              title{
                ${rootState.currentLanguage}
              }
              short_description{
                ${rootState.currentLanguage}
              }
              long_description{
                ${rootState.currentLanguage}
              }
              images{
                image_url
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            artists{
              id
              cover_image_url
              title{
                ${rootState.currentLanguage}
              }
              short_description{
                ${rootState.currentLanguage}
              }
              long_description{
                ${rootState.currentLanguage}
              }
              images{
                image_url
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            seo{     id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
              }
  photographers_seo{     id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
              }
  albums_seo{     id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
              }
  tea_inspired_me_seo{     id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
              }
  artists_seo{     id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
              }
          }
        }
      }
    `,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_TEA_INSPIREDIMAGES", response.data.teaInspiredImagePages);
    });
  },

  async getTeamakersPhilosophy({ commit, rootState }) {
    const graphqlq = {
      query: `{
        teamakersPhilosophyPages(where:{
          country:"${rootState.currentCountry}"
        }){
            id
            country
            page{
              id
              contents{ id
                      has_header
                      has_link
                      link
                      header{
                        ${rootState.currentLanguage} 
                      }
                      content{
                        ${rootState.currentLanguage} 
                      }
                      link_text{ 
                        ${rootState.currentLanguage} 
                      }
                      image_url
                      sub_heading{ 
                        ${rootState.currentLanguage} 
                      }
                      has_sub_heading
                      has_video
                      video_url}
              banner_image_url
              title_content{ id
                      has_header
                      has_link
                      link
                      header{ 
                        ${rootState.currentLanguage} 
                      }
                      content{
                        ${rootState.currentLanguage} 
                      }
                      link_text{
                        ${rootState.currentLanguage} 
                      }
                      image_url
                      sub_heading{
                        ${rootState.currentLanguage}
                      }
                      has_sub_heading
                      has_video
                      video_url}
              banner_title{ 
                ${rootState.currentLanguage} 
                      }
            }
          }
        }
        `,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_TEAMAKERS_PHILOSOPHY", response.data);
    });
  },

  async getInspiredHome({ commit }) {
    await this.$axios
      .$get(`dmc-apis/tea_inspired_home?country=GLOBAL&lang=en`)
      .then((response) => {
        commit("SET_INSPIRED_HOME", response);
      });
  },
};
