//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    linkPath(url) {
      return this.$router.push({
        path: `${url}`,
        query: this.$route.query,
      });
    },
    urlClick(url) {
      if (
        url.split("/")[1] != "our-products" &&
        url.split("/")[1] != "dilmah-family" &&
        url.split("/")[1] != "all-about-tea" &&
        url.split("/")[1] != "our-purpose" &&
        url.split("/")[1] != "tea-inspired" &&
        typeof this.$route.query != "undefined"
      ) {
        this.linkPath(url);
      }
      if (
        typeof url.split("/")[3] != "undefined" &&
        url.split("/")[1] != "dilmah-family" &&
        typeof this.$route.query != "undefined"
      ) {
        this.linkPath(url);
      }
      if (
        url.split("/")[2] == "dilmah-podcasts" ||
        (url.split("/")[2] == "tea-inspired-e-books" &&
          typeof this.$route.query != "undefined")
      ) {
        this.linkPath(url);
      }
      if (
        typeof url.split("/")[3] != "undefined" &&
        typeof this.$route.query != "undefined" &&
        url.split("/")[1] == "our-products"
      ) {
        this.linkPath(url);
      }
      if (url.split("/")[2] == "our-tea-garden") {
        this.linkPath(url);
      }
      if (
        url.split("/")[2] == "prealbums" ||
        url.split("/")[2] == "mediaphotographers"
      ) {
        this.linkPath(url);
      }
      if (
        url.split("/")[2] == "tea-inspiredme" ||
        url.split("/")[2] == "artists"
      ) {
        this.linkPath(url);
      }

      // if (
      //   (url.split("/")[2] == "prealbums",
      //   url.split("/")[2] == "mediaphotographers",
      //   url.split("/")[2] == "tea-inspiredme",
      //   url.split("/")[2] == "artists")
      // ) {
      //   this.linkPath(url);
      // }
    },
  },
  computed: {},
};
