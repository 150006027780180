//
//
//
//
//
//

import desktopNavigation from "~/components/desktop-navigation";
import mobileNavigation from "~/components/mobile-navigation";

export default {
  components: { desktopNavigation, mobileNavigation },
  mounted() {
    this.$store.dispatch("getLanguagesAndCountries");
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 100) {
        $(".desktopNav").addClass("scrolled");
      } else {
        $(".desktopNav").removeClass("scrolled");
      }
    });
  },
};
