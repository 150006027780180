export const state = () => ({
  recipes_list: null,
});

export const mutations = {
  SET_RECIPES_LISTING(state, data) {
    state.recipes_list = data;
  },
};
export const actions = {
  async getRecipesListing({ commit }, payload) {
    await this.$axios.$get(`dmc-apis/get_elixir_recipes`).then((response) => {
      commit("SET_RECIPES_LISTING", response);
    });
  },
};
