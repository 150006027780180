//
//
//
//
//
//
//
//

import navigation from "../components/navigation";
import footerComp from "../components/footer-comp";
import PrivacyPolicyCookie from "~/components/privacy-policy-cookie";
export default {
  // data() {
  //   // return {
  //   //   navShow: false,
  //   // };
  // },
  components: {
    navigation,
    footerComp,
    PrivacyPolicyCookie,
  },
  beforeMount() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
    });
  },
  beforeUpdate() {
    this.$nuxt.$loading.start();
  },
  updated() {
    setTimeout(() => {
      this.$nuxt.$loading.finish();
      // this.navShow = true;
    }, 800);
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$nuxt.$loading.finish();
        // this.navShow = true;
      }, 800);
      this.getWindowWidth();
      window.addEventListener("resize", this.getWindowWidth);
    });
  },
  methods: {
    getWindowWidth() {
      this.$store.commit("SET_WINDOW_WIDTH", document.body.clientWidth);
      if (this.$store.state.windowWidth < 992) {
        this.$store.commit("SET_MOBILE_STATE", true);
      } else {
        this.$store.commit("SET_MOBILE_STATE", false);
      }
    },
  },
};
