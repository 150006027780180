export const state = () => ({
    termsConditions: null,
    privacyPolicies: null,
    bribaryPolicies: null,
    businessEthics: null,
    humanRights: null,
});

export const mutations = {
    SET_TERMS_CONDITIONS(state, data) {
        state.termsConditions = data
    },
    SET_PRIVACY_POLICY(state, data) {
        state.privacyPolicies = data
    },
    SET_BRIBARY_POLICY(state, data) {
        state.bribaryPolicies = data
    },
    SET_BUSINESS_ETHICS(state, data) {
        state.businessEthics = data
    },
    SET_HUMAN_RIGHTS(state, data) {
        state.humanRights = data
    }
}

export const actions = {

    async gettermsConditionsPage({ commit }) {
        const graphqlq = {
            query: `{
                termsAndConditionsPages(where: { country: "GLOBAL" }) {
                    id
                    country
                    page {
                      id
                      title {
                        text_en
                      }
                      header_image_url
                      header_description_1 {
                        text_en
                      }
                      header_description_2 {
                        text_en
                      }
                      policy_content {
                        id
                        title {
                          text_en
                        }
                        description {
                          text_en
                        }
                      }
                      seo{     
                        id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                    }
                    }
                  }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_TERMS_CONDITIONS", response.data.termsAndConditionsPages[0].page);
        });
    },
    async getprivacypolicies({ commit }) {
        const graphqlq = {
            query: `{
              privacyAndCookiePolicyPages (where:{
                  country:"GLOBAL"
                }){
                  id
                  country
                  page{
                    id
                    title{
                      text_en
                  }
                    header_image_url
                    policy_content1{
                      id
                      title{
                      text_en
                  }
                       description{
                      text_en
                  }
                    }
                    policy_content2{
                      title{
                          text_en
                      }
                      description{
                          text_en
                      }
                  }
                    what_we_collect_content{
                      text_en
                  }
                    mobile_app_policy_content{
                      text_en
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                  }
                }
              }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_PRIVACY_POLICY", response.data.privacyAndCookiePolicyPages[0].page);
        });
    },
    async getbribaryConditionsPage({ commit }) {
        const graphqlq = {
            query: `{
            antiBribaryAndCorruptionPolicyPages (where:{
              country:"GLOBAL"
            }){
              id
              country
              page{
                id
                title{
                  text_en 
                }
                header_image_url
                policy_content1{
                  title{
                      text_en 
                  }
                  description{
                      text_en 
                }}
                policy_content2{
                  title{  
                      text_en 
                  }
                  description{
                      text_en 
                  }}
                   policy_content1_description{
                      text_en 
                  }
                  policy_content2_description{
                  text_en 
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
              }
            }
        }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_BRIBARY_POLICY", response.data.antiBribaryAndCorruptionPolicyPages[0].page);
        });
    },

    async getbusinessEthics({ commit }) {
        const graphqlq = {
            query: `{
          businessEthicsPolicyPages (where:{
            country:"GLOBAL"
            })
            {
            id
            country
            page{
              id
              title{
                text_en 
              }
              header_image_url
              policy_content1{
                title{
                    text_en 
                }
                description{
                    text_en 
              }}
              policy_content2{
                title{  
                    text_en 
                }
                description{
                    text_en 
                }}
                 policy_content1_description{
                    text_en 
                }
                policy_content2_description{
                text_en 
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
        }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_BUSINESS_ETHICS", response.data.businessEthicsPolicyPages[0].page);
        });
    },
    async gethumanRightsPage({ commit }) {
        const graphqlq = {
            query: `{
        humanRightsPolicyPages (where:{
         country:"GLOBAL"
       }){
         id
         country
         page{
           id
           title{
             text_en 
           }
           header_image_url
           policy_content1{
             title{
                 text_en 
             }
             description{
                 text_en 
           }}
           policy_content2{
             title{  
                 text_en 
             }
             description{
                 text_en 
             }}
              policy_content1_description{
                 text_en 
             }
             policy_content2_description{
             text_en 
         }
         seo{     
          id 
          meta_title
          meta_description 
          keywords
          share_image_alt
          share_image_url
          prevent_indexing 
      }
         }
       }
     }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_HUMAN_RIGHTS", response.data.humanRightsPolicyPages[0].page);
        });
    },


}