import _ from "lodash";
export const state = () => ({
  imagesUrls: [],
  imageLinks: null,
  categories: null,
  singleImage: null,
  recentAlbums: null,
  videoListing: null,
  dilmahInPress: null,
  downloadImage: null,
  teaBlogListing: null,
  audioListening: null,
  imageMostShares: null,
  getImagesListing: null,
  currentCountry: "GLOBAL",
  singleDilmahInPress: null,
  currentLanguage: "text_en",
  mediaPage: null,
  bannerCustomization: null,
});

export const mutations = {
  SET_AUDIO_LISTENING(state, data) {
    state.audioListening = data;
  },
  CLEAR_AUDIO_LISTENING(state) {
    state.audioListening = null;
  },
  SET_IMAGES_LISTENING(state, data) {
    state.getImagesListing = data.data;
    state.recentAlbums = data.recentItems;
    state.imageMostShares = data.mostShared;
  },
  SET_VIDEO_LISTENING(state, data) {
    state.videoListing = data;
  },
  CLEAR_VIDEO_LISTENING(state) {
    state.videoListing = null;
  },
  SET_IMAGES_URLS(state, data) {
    let tempImgs = state.imagesUrls.find((item) => item == data);
    if (typeof tempImgs == "undefined") {
      state.imagesUrls.push(data);
    }
  },
  GET_IMAGE_BY_ID(state, data) {
    state.singleImage = data;
  },
  SET_REMOVE_IMAGES_URL_COMPLETE(state, data) {
    state.imagesUrls = [];
  },
  SET_REMOVE_IMAGES_URLS(state, data) {
    state.imagesUrls.splice(data, 1);
  },
  SET_TEA_BLOG_LISTING(state, data) {
    state.teaBlogListing = data;
  },
  SET_INPRESS_CATEGORIES(state, data) {
    state.categories = data;
  },
  SET_DILMAH_IN_PRESS(state, data) {
    state.dilmahInPress = data;
  },
  GET_SINGLE_DILMAH_IN_PRESS(state, data) {
    state.singleDilmahInPress = data;
  },
  CLEAR_INPRESS_RESULTS(state) {
    state.dilmahInPress = null;
  },
  CLEAR_IMAGE_ALBUMS_RESULTS(state) {
    state.getImagesListing = null;
  },
  CLEAR_ALBUM_SINGLES_RESULTS(state) {
    state.singleImage = null;
  },
  CLEAR_DILMAH_IN_PRESS(state) {
    state.singleDilmahInPress = null;
  },
  CLEAR_AUDIO_LISTENING(state) {
    state.audioListening = null;
  },
  CLEAR_VIDEO_LISTENING(state) {
    state.videoListing = null;
  },
  SET_MEDIA_PAGE(state, data) {
    state.mediaPage = data;
  },
  SET_BANNER_CUSTOMIZATION(state, data) {
    state.bannerCustomization = data;
  },
};
export const actions = {
  getAudioListening: _.debounce(async function({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/audio?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}&year=${payload.year}&subCategory=${payload.subCategory}`
      )
      .then((response) => {
        commit("SET_AUDIO_LISTENING", response.data);
      });
  }, 500),
  getImagesListening: _.debounce(async function({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/images?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}`
      )
      .then((response) => {
        commit("SET_IMAGES_LISTENING", response);
      });
  }, 500),
  // async getImagesById({ commit, state }, payload) {
  //   await this.$axios
  //     .$get(`dmc-apis/images_by_id/${payload.id}`)
  //     .then((response) => {
  //       commit("GET_IMAGE_BY_ID", response);
  //     });
  // },
  async getImagesById({ commit, state }, id) {
    await this.$axios.$get(`dmc-apis/images_by_name/${id}`).then((response) => {
      commit("GET_IMAGE_BY_ID", response);
    });
  },
  getVideoListening: _.debounce(async function({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/video?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}&year=${payload.year}&subCategory=${payload.subCategory}`
      )
      .then((response) => {
        commit("SET_VIDEO_LISTENING", response);
      });
  }, 500),
  async imageUrls({ commit }, payload) {
    commit("SET_IMAGES_URLS", payload);
  },
  async removeUrlIndex({ commit }, payload) {
    commit("SET_REMOVE_IMAGES_URLS", payload);
  },
  async removeSelectedImgs({ commit }, payload) {
    commit("SET_REMOVE_IMAGES_URL_COMPLETE");
  },
  async getteaBlogListing({ commit }) {
    const graphqlq = {
      query: `{
            teaBlogPages(where: { country: "GLOBAL" }) {
                country
                page {
                  header_tea_blog {
                    id
                    image_url
                    header {
                      text_en
                    }
                    long_description {
                      text_en
                    }
                    date
                    is_featured
                    short_description {
                      text_en
                    }
                    tea_blog_category {
                      id
                      name {
                        text_en
                      }
                    }
                    author {
                      text_en
                    }
                    share_count
                  }
                  dilmah_tea_blogs {
                    id
                    image_url
                    header {
                      text_en
                    }
                    long_description {
                      text_en
                    }
                    date
                    is_featured
                    short_description {
                      text_en
                    }
                    tea_blog_category {
                      id
                      name {
                        text_en
                      }
                    }
                    author {
                      text_en
                    }
                    share_count
                  }
                }
              }
          }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_TEA_BLOG_LISTING", response.data.teaBlogPages[0]);
    });
  },

  getDilmahInPress: _.debounce(async function({ commit, state }, payload) {
    await this.$axios
      .$get(
        `dmc-apis/press?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}&year=${payload.year}&month=${payload.month}&subCategory=${payload.subCategory}&category=${payload.category}`
      )
      .then((response) => {
        commit("SET_DILMAH_IN_PRESS", response);
      });
  }, 500),
  // async getSingleDilmahInPressById({ commit, state }, payload) {
  //   await this.$axios
  //     .$get(`dmc-apis/press_by_id/${payload.id}`)
  //     .then((response) => {
  //       commit("GET_SINGLE_DILMAH_IN_PRESS", response);
  //     });
  // },
  async getSingleDilmahInPressById({ commit, state }, id) {
    await this.$axios.$get(`dmc-apis/press_by_name/${id}`).then((response) => {
      commit("GET_SINGLE_DILMAH_IN_PRESS", response);
    });
  },

  async getCategories({ commit }) {
    await this.$axios.$get(`/dmc-apis/press_categories`).then((response) => {
      commit("SET_INPRESS_CATEGORIES", response);
    });
  },
  async getMedia({ commit, rootState }) {
    const graphqlq = {
      query: `{
        mediaPages(where:{
          country:"GLOBAL"
        }){
          id
          country
          page{
            id
            latest_news_cover{ id
                        image_url
                        link
                        title{${rootState.currentLanguage}}
                        description{${rootState.currentLanguage}}
                        header{${rootState.currentLanguage}}
                        seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }}
            events_cover{ id
                        image_url
                        link
                        title{${rootState.currentLanguage}}
                        description{${rootState.currentLanguage}}
                        header{${rootState.currentLanguage}}
                        seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }}
            tea_blog_cover{ id
                        image_url
                        link
                        title{${rootState.currentLanguage}}
                        description{${rootState.currentLanguage}}
                        header{${rootState.currentLanguage}}
                        seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }}
            dilmah_in_press_cover{ id
                        image_url
                        link
                        title{${rootState.currentLanguage}}
                        description{${rootState.currentLanguage}}
                        header{${rootState.currentLanguage}}
                        seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }}
            seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }
          }
        }
          }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit("SET_MEDIA_PAGE", response.data.mediaPages[0].page);
    });
  },
  async getBannerCustomization({ commit, rootState }) {
    const graphqlq = {
      query: `{
        bannerCustomizationPages (where:{
          country:"GLOBAL"
        }){
          id
          country
          page{
            id
            audio_title{${rootState.currentLanguage}}
            audio_list_header_image_url
            audio_seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }
            video_title{${rootState.currentLanguage}}
            video_list_header_image_url
            video_seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }
            image_title{${rootState.currentLanguage}}
            image_list_header_image_url
            image_seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }
            blog_title{${rootState.currentLanguage}}
            blog_list_header_image_url
            seo{     id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                        }
          }
        }
          }`,
    };

    await this.$axios.$post("/graphql", graphqlq).then((response) => {
      commit(
        "SET_BANNER_CUSTOMIZATION",
        response.data.bannerCustomizationPages[0].page
      );
    });
  },
};
