import { render, staticRenderFns } from "./nav-fixed.vue?vue&type=template&id=3546ecd8&"
import script from "./nav-fixed.vue?vue&type=script&lang=js&"
export * from "./nav-fixed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/app/components/navigation.vue').default,FooterComp: require('/app/components/footer-comp.vue').default,PrivacyPolicyCookie: require('/app/components/privacy-policy-cookie.vue').default})
