import Vue from "vue";
import { mapState } from "vuex";

Vue.mixin({
  computed: {
    ...mapState({
      $language: (state) => state.currentLanguage,
      $isMobile: (state) => state.isMobile,
      $winW: (state) => state.windowWidth,
    }),
  },
});
