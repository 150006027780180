import _ from "lodash";
export const state = () => ({
    foundersMsg: null,
    dilmahStory: null,
    ourValues: null,
    ourTeaGardens: null,
    certificatesAndAwards: null,
    whatWeDo: null,
    leisureAndHospitality: null,
    dilmahGlobalTimeline: null,
    testimonials: null,
    currentLanguage: "${rootState.currentLanguage}",
});

export const mutations = {
    SET_FOUNDERS_MSG(state, data) {
        state.foundersMsg = data;
    },
    SET_DILMAH_STORY(state, data) {
        state.dilmahStory = data;
    },
    SET_OUR_VALUES(state, data) {
        state.ourValues = data;
    },
    SET_OUR_TEA_GARDENS(state, data) {
        state.ourTeaGardens = data;
    },
    SET_CERTIFICATES_AND_AWARDS(state, data) {
        state.certificatesAndAwards = data;
    },
    SET_WHAT_WE_DO(state, data) {
        state.whatWeDo = data;
    },
    SET_LEISURE_AND_HOSPITALITY(state, data) {
        state.leisureAndHospitality = data;
    },
    SET_DILMAH_GLOBAL_TIMELINE(state, data) {
        state.dilmahGlobalTimeline = data;
    },
    SET_TESTIMONIALS(state, data) {
        state.testimonials = data;
    },
    CLEAR_TESTIMONIALS(state) {
        state.testimonials = null;
    },
};

export const actions = {
    // async nuxtServerInit({commit, dispatch }, {req}) {
    // },

    async getFoundersMsgPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
          foundersMessagePages(where: { country: "${rootState.currentCountry}" }) {
            country
            page {
              title_content {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              video_url
              signature_url
        
              founder_name {
                ${rootState.currentLanguage}
              }
              sliders {
                slider_has_video
                video_url
                image_url
                header {
                  ${rootState.currentLanguage}
                }
              }
              contents {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
        }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_FOUNDERS_MSG", response.data.foundersMessagePages[0].page);
        });
    },
    async getDilmahStoryPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
          dilmahStoryPages(where: { country: "${rootState.currentCountry}" }) {
            id
            country
            page {
                title_content {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              video_url
              contents {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
        }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_DILMAH_STORY", response.data.dilmahStoryPages[0].page);
        });
    },
    async getOurValuesPage({ commit }) {
        const graphqlq = {
            query: `{
          ourValuesPages(where: { country: "GLOBAL" }) {
            id
            country
            page {
              contents {
                has_header
                has_link
                link
                header {
                  text_en
                }
                content {
                  text_en
                }
                link_text {
                  text_en
                }
                image_url
              }
              title_content {
                has_header
                has_link
                link
                header {
                  text_en
                }
                content {
                  text_en
                }
                link_text {
                  text_en
                }
                image_url
              }
              video_content {
                has_header
                has_link
                link
                header {
                  text_en
                }
                content {
                  text_en
                }
                link_text {
                  text_en
                }
                image_url
              }
              sliders {
                slider_has_video
                video_url
                image_url
                header {
                  text_en
                }
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
        }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_OUR_VALUES", response.data.ourValuesPages[0].page);
        });
    },
    async getOurTeaGardensPage({ commit }) {
        const graphqlq = {
            query: `{
          ourTeaGardensPages(where: { country: "GLOBAL" }) {
            id
            country
            page {
              header {
                text_en
              }
              description {
                text_en
              }
              tea_state_items {
                type
                video_link
                longitude
                latitude
                video_thumbnail_url
                name {
                  text_en
                }
                description {
                  text_en
                }
                images {
                  image_url
                }
                cover_image_url

                tour_link_multilang {
                  text_en
                }
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_OUR_TEA_GARDENS", response.data.ourTeaGardensPages[0].page);
        });
    },
    async getCertificatesAndAwardsPage({ commit }) {
        const graphqlq = {
            query: `{
          certificatesAndAwardsPages(where: { country: "GLOBAL" }) {
            id
            country
            page {
              header {
                text_en
              }
              description {
                text_en
              }
              award_btn_text {
                text_en
              }
              certificate_btn_text {
                text_en
              }
              certificates {
                header {
                  text_en
                }
                description {
                  text_en
                }
                certificate_or_awards {
                  image_url
                  file_url
                  description {
                    text_en
                  }
                  sub_heading{
                    text_en
                  }
                }
              }
              awards {
                header {
                  text_en
                }
                description {
                  text_en
                }
                certificate_or_awards {
                  image_url
                  file_url
                  description {
                    text_en
                  }
                  sub_heading{
                    text_en
                  }
                }
              }
              seo{     
                id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
            }
            }
          }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_CERTIFICATES_AND_AWARDS",
                response.data.certificatesAndAwardsPages[0].page);
        });
    },
    async getWhatWeDoPage({ commit }) {
        const graphqlq = {
            query: `{
          whatWeDoPages(where: { country: "GLOBAL" }) {
          id
          country
          page {
            contents {
              has_header
              has_link
              link
              header {
                text_en
              }
              content {
                text_en
              }
              link_text {
                text_en
              }
              image_url
              logo_url
            }
            seo{     
              id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
          }
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_WHAT_WE_DO", response.data.whatWeDoPages[0].page);
        });
    },
    async getLeisureAndHospitalityPage({ commit }) {
        const graphqlq = {
            query: `{
          leisureAndHospitalityPages(where: { country: "GLOBAL" }) {
          id
          country
          page {
            contents {
              has_header
              has_link
              link
              header {
                text_en
              }
              content {
                text_en
              }
              link_text {
                text_en
              }
              image_url
              logo_url
            }
            seo{     
              id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
          }
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_LEISURE_AND_HOSPITALITY",
                response.data.leisureAndHospitalityPages[0].page
            );
        });
    },
    async getDilmahGlobalTimelinePage({ commit }) {
        const graphqlq = {
            query: `{
          dilmahGlobalTimelinePages(where: { country: "GLOBAL" }) {
          id
          country
          page {
            time_line {
              year
              title {
                text_en
              }
              content {
                text_en
              }
              images {
                image_url
              }
            }
            seo{     
              id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
          }
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_DILMAH_GLOBAL_TIMELINE",
                response.data.dilmahGlobalTimelinePages[0].page
            );
        });
    },
    // async getTestimonialsPage({ commit }) {
    //     const graphqlq = {
    //         query: `{
    //       testimonialsPages(where: { country: "GLOBAL" }) {
    //         id
    //         country
    //         page {
    //           testimonial_items{
    //             name{
    //               text_en
    //             }
    //             title{
    //               text_en
    //             }
    //             content{
    //               text_en
    //             }
    //             short_description{
    //               text_en
    //             }
    //             image_url
    //             date
    //           }
    //         }
    //       }
    //     }`
    //     }

    //     await this.$axios.$post("/graphql", graphqlq).then(response => {
    //         commit("SET_TESTIMONIALS", response.data.testimonialsPages);
    //     });
    // },
    getTestimonials: _.debounce(async function({ commit, state }, payload) {
        await this.$axios
            .$get(
                `dmc-apis/filterTestimonials?page=${payload.page}&size=${payload.size}&year=${payload.year}&month=${payload.month}&keyword=${payload.filteredText}`
            )
            .then((response) => {
                commit("SET_TESTIMONIALS", response);
            });
    }, 500),
};