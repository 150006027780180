import _ from "lodash";
export const state = () => ({
    schoolofTea: null,
    schoolofteaELearning: null,
    teaandHealth: null,
    teainspiredEBook: null,
    factsofTea: null,
    dilmahPodcast: null,
    perfectCupTea: null
});

export const mutations = {
    SET_SCHOOL_OF_TEA(state, data) {
        state.schoolofTea = data
    },
    SET_SCHOOL_OF_TEA_ELEARNING(state, data) {
        state.schoolofteaELearning = data
    },
    SET_TEA_AND_HEALTH(state, data) {
        state.teaandHealth = data
    },
    SET_TEA_INSPIRED_EBOOK(state, data) {
        state.teainspiredEBook = data
    },
    SET_FACTS_OF_TEA(state, data) {
        state.factsofTea = data
    },
    SET_DILMAH_PODCAST(state, data) {
        state.dilmahPodcast = data
    },
    SET_PERFECT_CUP_TEA(state, data) {
        state.perfectCupTea = data
    }
}

export const actions = {
    async getSchoolOfTeaPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
                schoolOfTeaPages(where: { country: "GLOBAL" }) {
                    id
                    country
                    page {
                      title {
                        ${rootState.currentLanguage}
                      }
                      header_image_url
                      contents {
                        has_header
                        has_link
                        link
                        header {
                          ${rootState.currentLanguage}
                        }
                        content {
                          ${rootState.currentLanguage}
                        }
                        link_text {
                          ${rootState.currentLanguage}
                        }
                        image_url
                      }
                      seo{     
                        id 
                        meta_title
                        meta_description 
                        keywords
                        share_image_alt
                        share_image_url
                        prevent_indexing 
                    }
                    }
                  }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_SCHOOL_OF_TEA", response.data.schoolOfTeaPages[0].page);
        });
    },
    async getSchoolOfTeaELearningPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
                    schoolOfTeaELearningPages(where: { country: "GLOBAL" }) {
                        id
                        country
                        page {
                          title {
                            ${rootState.currentLanguage}
                          }
                          header_image_url
                          contents {
                            has_header
                            has_link
                            link
                            header {
                              ${rootState.currentLanguage}
                            }
                            content {
                              ${rootState.currentLanguage}
                            }
                            link_text {
                              ${rootState.currentLanguage}
                            }
                            image_url
                          }
                          seo{     
                            id 
                            meta_title
                            meta_description 
                            keywords
                            share_image_alt
                            share_image_url
                            prevent_indexing 
                        }
                        }
                      }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_SCHOOL_OF_TEA_ELEARNING", response.data.schoolOfTeaELearningPages[0].page);
        });
    },
    async getTeaAndHealthPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
                    teaAndHealthPages(where: { country: "GLOBAL" }) {
                        id
                        country
                        page {
                          title {
                            ${rootState.currentLanguage}
                          }
                          header_image_url
                          contents {
                            has_header
                            has_link
                            link
                            header {
                              ${rootState.currentLanguage}
                            }
                            content {
                              ${rootState.currentLanguage}
                            }
                            link_text {
                              ${rootState.currentLanguage}
                            }
                            image_url
                          }
                          seo{     
                            id 
                            meta_title
                            meta_description 
                            keywords
                            share_image_alt
                            share_image_url
                            prevent_indexing 
                        }
                        }
                      }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_TEA_AND_HEALTH", response.data.teaAndHealthPages[0].page);
        });
    },
    async getTeaInspiredEBooksPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
                    teaInspiredEBooksPages(where: { country: "GLOBAL" }) {
                        id
                        country
                        page {
                          title {
                            ${rootState.currentLanguage}
                          }
                          header_image_url
                          e_book_sections {
                            title {
                              ${rootState.currentLanguage}
                            }
                            e_book_elements {
                              title {
                                ${rootState.currentLanguage}
                              }
                              content {
                                ${rootState.currentLanguage}
                              }
                              image_url
                              book_url
                            }
                          }
                          seo{     
                            id 
                            meta_title
                            meta_description 
                            keywords
                            share_image_alt
                            share_image_url
                            prevent_indexing 
                        }
                        }
                      }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_TEA_INSPIRED_EBOOK", response.data.teaInspiredEBooksPages[0].page);
        });
    },
    async getFactsOfTeaPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
                    factsOfTeaPages(where: { country: "${rootState.currentCountry}" }) {
                        id
                        country
                        page {
                          title {
                            ${rootState.currentLanguage}
                          }
                          header_image_url
                          contents {
                            has_header
                            has_link
                            link
                            header {
                              ${rootState.currentLanguage}
                            }
                            content {
                              ${rootState.currentLanguage}
                            }
                            link_text {
                              ${rootState.currentLanguage}
                            }
                            image_url
                          }
                          seo{     
                            id 
                            meta_title
                            meta_description 
                            keywords
                            share_image_alt
                            share_image_url
                            prevent_indexing 
                        }
                        }
                      }
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_FACTS_OF_TEA", response.data.factsOfTeaPages[0].page);
        });
    },
    async getDelmahPodcastPage({ commit, rootState }) {
        const graphqlq = {
            query: `{
              dilmahPodcastPages(where: { country: "${rootState.currentCountry}" }) {
                id
                country
                page {
                  title {
                    ${rootState.currentLanguage}
                  }
                  header_image_url
                  feature_podcast {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                      created_at
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                    has_sub_heading
                    sub_heading {
                        ${rootState.currentLanguage}
                    }
                  }
                  podcast_sections {
                    title {
                      ${rootState.currentLanguage}
                    }
                    contents {
                      has_header
                      has_link
                      created_at
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
                    
          }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_DILMAH_PODCAST", response.data.dilmahPodcastPages[0].page);
        });
    },

    async getPerfectCupTea({ commit, rootState }) {
        const graphqlq = {
            query: `{
              howToBrewTeaPages(where: { country: "${rootState.currentCountry}" }) {
                id
                country
                page {
                  title {
                    ${rootState.currentLanguage}
                  }
                  header_image_url
                  content {
                    header {
                        ${rootState.currentLanguage}
                    }
                    content {
                        ${rootState.currentLanguage}
                    }
                  }
                  video_url
                  dynamic_content_sections {
                    type
                    has_header
                    rule_number
                    content {
                      ${rootState.currentLanguage}
                    }
                    image_url
                    has_link
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    link
                    headers {
                      ${rootState.currentLanguage}
                    }
                    sub_contents {
                      has_header
                      header {
                        ${rootState.currentLanguage}
                      }
                      image_url
                      contents {
                        ${rootState.currentLanguage}
                      }
                    }
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
            }`
        }

        await this.$axios.$post("/graphql", graphqlq).then(response => {
            commit("SET_PERFECT_CUP_TEA", response.data.howToBrewTeaPages[0].page);
        });
    }
}

export const getters = {
    brewRules: (state) => {
        if (state.perfectCupTea) {
            let ruleSet = state.perfectCupTea.dynamic_content_sections
            let gIndex = 0;
            let mappedRuleSet = ruleSet.flatMap((item => {
                item.gIndex = gIndex;
                gIndex++
                return item;
            }));
            let arrayData = [];
            _.forEach(
                _.groupBy(
                    mappedRuleSet,
                    "rule_number"
                ),
                (val) => {
                    arrayData.push(val);
                }
            );
            return arrayData;
        }
    },
}