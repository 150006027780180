import _ from "lodash";
export const state = () => ({
    currentCountry: "GLOBAL",
    currentLanguage: "${rootState.currentLanguage}",
    teaRangeName: null,
    teaRangeList: null,
    teaMakersPrivateReserve: null,
    tSeriesGourmet: null,
    elixirOfCeylonTea: null,
    naturalInfusions: null,
    watteBoutique: null,
    ceylonIcedTeaPages: null,
    filteredProduct: null,
    porductFilters: null,
    teaCategories: null,
    teaQuizData: null,
    teaquizResult: null,
    // naturalOurIngredients: [],
    infusionProductByIngredient: null,
    ingredientsPage: null,
});

export const mutations = {
    SET_TEA_RANGE(state, data) {
        state.teaRangeName = data;
    },
    SET_TEA_RANGE_LIST(state, data) {
        state.teaRangeList = data;
    },
    SET_TEA_MAKERS_PRIVATE_RESERVE(state, data) {
        state.teaMakersPrivateReserve = data.teaMakersPrivateReservePages[0].page;
    },
    SET_T_SERIES_GOURMET(state, data) {
        state.tSeriesGourmet = data.tSeriesGourmetPages[0].page;
    },
    SET_ELIXIR_OF_CEYLON_TEA(state, data) {
        state.elixirOfCeylonTea = data;
    },
    SET_NATURAL_INFUSIONS(state, data) {
        state.naturalInfusions = data;
    },
    SET_WATTE_BOUTIQUE(state, data) {
        state.watteBoutique = data;
    },
    SET_ICE_TEA(state, data) {
      state.ceylonIcedTeaPages = data;
  },
    SET_FILTERED_PRODUCTS(state, data) {
        state.filteredProduct = data;
    },
    CLEAR_FILTERED_PRODUCTS(state) {
        state.filteredProduct = null;
    },
    SET_FILTERS(state, data) {
        state.porductFilters = data;
    },
    SET_TEA_CATEGORY_DATA(state, data) {
        state.teaCategories = data;
    },
    SET_TEA_QUIZ_DATA(state, data) {
        state.teaQuizData = data;
    },
    SET_TEAQUIZ_RESULT(state, data) {
        state.teaquizResult = data;
    },
    // SET_NATURAL_OUR_INGREDIENTS(state, data) {
    //     state.naturalOurIngredients = data;
    // },
    SET_INFUSION_PRODUCT_BY_INGREDIENT(state, data) {
        state.infusionProductByIngredient = data;
    },
    CLEAR_INFUSION_PRODUCT_BY_INGREDIENT(state) {
        state.infusionProductByIngredient = null;
    },
    SET_INGREDIENTS(state, data) {
        state.ingredientsPage = data;
    },

    CLEAR_TEAQUIZ_RESULT(state) {
        state.teaquizResult = null;
    },
};

export const actions = {
    async getTeaRange({ commit, state }, payload) {
        commit("SET_TEA_RANGE", null);
        await this.$axios
            .$get(
                `dmc-apis/tearange_by_name?teaRangeName=${payload.teaRangeName}&country=${state.currentCountry}`
            )
            .then((response) => {
                commit("SET_TEA_RANGE", response);
            });
    },
    async getTeaRangeList({ commit, state }, payload) {
        await this.$axios
            .$get(
                `dmc-apis/tea_ranges?page=${payload.page}&size=${payload.size}&lang=${state.currentLanguage}`
            )
            .then((response) => {
                commit("SET_TEA_RANGE_LIST", response);
            });
    },

    async getTeaMakersPrivateReserve({ commit, rootState }) {
        const graphqlq = {
            query: `{
              teaMakersPrivateReservePages(where: { country: "${rootState.currentCountry}" }) {
                country
                page {
                  header_image_url
                  sub_header_image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  }
                  links {
                    image_url
                    title {
                      ${rootState.currentLanguage}
                    }
                    link
                  }
                  about {
                    header_image_url
                    sub_header_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    initial_content {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                      sub_heading {
                        ${rootState.currentLanguage}
                      }
                      has_sub_heading
                      has_video
                      video_url
                    }
                    contents {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                  }
             
                  our_teas {
                    header_image_url
                    sub_header_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    sub_content {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                  }
             
                  tea_locations {
                    header_image_url
                    sub_header_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    topic {
                      ${rootState.currentLanguage}
                    }
                    tea_locations {
                      latitude
                      longitude
                      title {
                        ${rootState.currentLanguage}
                      }
                      description {
                        ${rootState.currentLanguage}
                      }
                      image_url
                  image_text_item{
                    ${rootState.currentLanguage} 
                  }
                    }
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_TEA_MAKERS_PRIVATE_RESERVE", response.data);
            //$nuxt.$loading.finish();
        });
    },
    async getTeaCategories({ commit, rootState }) {
        const graphqlq = {
            query: `{
        ourTeaCategoriesPages(where: { country: "${rootState.currentCountry}" }) {
          id
          country
          page {
            id
            title {
              ${rootState.currentLanguage}
            }
            header_image_url
            header_descrpiton {
              title {
                ${rootState.currentLanguage}
              }
              description {
                ${rootState.currentLanguage}
              }
            }
            tea_ranges {
              id
              header_image
              title {
                ${rootState.currentLanguage}
              }
              items {
                id
                image_url
                header_image_url
                title_text
                description {
                  ${rootState.currentLanguage}
                }
                link
              }
              header_descrpiton {
                id
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            tea_types {
              id
              header_image
              title {
                ${rootState.currentLanguage}
              }
              items {
                id
                image_url
                header_image_url
                title_text
                description {
                  ${rootState.currentLanguage}
                }
                link
                seo{     id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
                  }
              }
              header_descrpiton {
                id
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
             
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
     
            tea_formats {
              id
              header_image
              title {
                ${rootState.currentLanguage}
              }
              items {
                id
                image_url
                header_image_url
                title_text
                description {
                  ${rootState.currentLanguage}
                }
                link
                seo{     id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
                  }
              }
              header_descrpiton {
                id
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
              }
              seo{     id 
                meta_title
                meta_description 
                keywords
                share_image_alt
                share_image_url
                prevent_indexing 
                }
            }
            tea_by_flavors {
              id
              header_image
              title {
                ${rootState.currentLanguage}
              }
              items {
                id
                image_url
                header_image_url
                title_text
                description {
                  ${rootState.currentLanguage}
                }
                link
              }
              header_descrpiton {
                id
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
              }
            }
            tea_by_moods {
              id
              header_image
              title {
                ${rootState.currentLanguage}
              }
              items {
                id
                image_url
                header_image_url
                title_text
                description {
                  ${rootState.currentLanguage}
                }
                link
              }
              header_descrpiton {
                id
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
              }
            }
  
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_TEA_CATEGORY_DATA",
                response.data.ourTeaCategoriesPages[0].page
            );
            //$nuxt.$loading.finish();
        });
    },

    async getTSeriesGourmet({ commit, rootState }) {
        const graphqlq = {
            query: `{
             tSeriesGourmetPages(where: { country: "${rootState.currentCountry}" }) {
                country
                page {
                  header_image_url
                  header_sub_image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  has_video
                  video_url
                  }
                  links {
                    image_url
                    title {
                      ${rootState.currentLanguage}
                    }
                    link
                  }
                  gourmet_teas {
                    header_image_url
                    header_sub_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      has_sub_heading
                      sub_heading {
                        ${rootState.currentLanguage}
                      }
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                  }
                  very_special_rare_teas {
                    header_image_url
                    header_sub_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      has_sub_heading
                      sub_heading {
                        ${rootState.currentLanguage}
                      }
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                  }
                  seasonal_flush {
                    header_image_url
                    header_sub_image_url
                    header {
                      ${rootState.currentLanguage}
                    }
                    title_content {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                    quote_content {
                      has_header
                      has_link
                      link
                      header {
                        ${rootState.currentLanguage}
                      }
                      content {
                        ${rootState.currentLanguage}
                      }
                      link_text {
                        ${rootState.currentLanguage}
                      }
                      image_url
                    }
                    featured_videos{ id
                      image_url
                      video_url
                      description{
                        text_global
                        ${rootState.currentLanguage} 
                      }
                      header{
                        text_global
                        ${rootState.currentLanguage} 
                      }
                    }
                  }
                  seo{     
                    id 
                    meta_title
                    meta_description 
                    keywords
                    share_image_alt
                    share_image_url
                    prevent_indexing 
                }
                }
              }
            }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_T_SERIES_GOURMET", response.data);
            //$nuxt.$loading.finish();
        });
    },

    async getElixirOfCeylonTea({ commit, rootState }) {
        const graphqlq = {
            query: `{
        elixirOfCeylonTeaPages(where: { country: "${rootState.currentCountry}" }) {
          country
          page {
            header_image_url
            header_sub_image_url
            header {
              ${rootState.currentLanguage}
            }
            content {
              has_header
              has_link
              link
              header {
                ${rootState.currentLanguage}
              }
              content {
                ${rootState.currentLanguage}
              }
              link_text {
                ${rootState.currentLanguage}
              }
              image_url
              has_video
              video_url
            }
            links {
              image_url
              title {
                ${rootState.currentLanguage}
              }
              link
            }
      
            our_elixir {
              header_image_url
              header_sub_image_url
              header {
                ${rootState.currentLanguage}
              }
              topic {
                ${rootState.currentLanguage}
              }
              elixir_topic {
                ${rootState.currentLanguage}
              }
              elixir_items {
                id
                image_url
                title {
                  ${rootState.currentLanguage}
                }
                description {
                  ${rootState.currentLanguage}
                }
              }
            }
            brewing_guideline {
              header_image_url
              header_sub_image_url
              header {
                ${rootState.currentLanguage}
              }
              topic {
                ${rootState.currentLanguage}
              }
              content_1 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              content_2 {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              videos {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
              images {
                image_url
                title {
                  ${rootState.currentLanguage}
                }
              }
            }
            real_tea {
              header_image_url
              header_sub_image_url
              header {
                ${rootState.currentLanguage}
              }
              topic{
                ${rootState.currentLanguage}
              }
              title_content {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
                has_sub_heading
                sub_heading{
                  ${rootState.currentLanguage}
                }
                has_video
                video_url
              }
              contents {
                has_header
                has_link
                link
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  ${rootState.currentLanguage}
                }
                link_text {
                  ${rootState.currentLanguage}
                }
                image_url
              }
            }
            elixir_recipes{
              header_image_url
              header_sub_image_url
              header{
                ${rootState.currentLanguage}
              }
              topic{
                ${rootState.currentLanguage}
              }
            }
            seo{     
              id 
              meta_title
              meta_description 
              keywords
              share_image_alt
              share_image_url
              prevent_indexing 
          }
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_ELIXIR_OF_CEYLON_TEA",
                response.data.elixirOfCeylonTeaPages[0].page
            );
            //$nuxt.$loading.finish();
        });
    },

    async getNaturalInfusions({ commit, rootState }) {
        const graphqlq = {
            query: `{
            naturalInfusionsPages(where: { country: "${rootState.currentCountry}" }) {
              country
              page {
                header_image_url
                header_sub_image_url
                header {
                  ${rootState.currentLanguage}
                }
                content {
                  has_header
                  has_link
                  link
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    ${rootState.currentLanguage}
                  }
                  link_text {
                    ${rootState.currentLanguage}
                  }
                  image_url
                  has_video
                  video_url
                }
                links {
                  image_url
                  title {
                    ${rootState.currentLanguage}
                  }
                  link
                }
                our_infusions {
                  header_image_url
                  header_sub_image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  }
                  topic {
                    ${rootState.currentLanguage}
                  }
                }
                our_ingredients {
                  header_image_url
                  header_sub_image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    sub_heading {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  }
                  video_content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  }
                  topic {
                    ${rootState.currentLanguage}
                  }
                  herbs_descriptions {
                    title {
                        ${rootState.currentLanguage}
                    }
                    description {
                        ${rootState.currentLanguage}
                    }
                  }
                  image_with_titles {
                    image_url
                    title {
                        ${rootState.currentLanguage}
                    }
                }
                }
                for_every_mood {
                  header_image_url
                  header_sub_image_url
                  header {
                    ${rootState.currentLanguage}
                  }
                  content {
                    has_header
                    has_link
                    link
                    header {
                      ${rootState.currentLanguage}
                    }
                    sub_heading {
                      ${rootState.currentLanguage}
                    }
                    content {
                      ${rootState.currentLanguage}
                    }
                    link_text {
                      ${rootState.currentLanguage}
                    }
                    image_url
                  }
                  mood_image_list {
                    image_url
                    title {
                    ${rootState.currentLanguage}
                  }
                }
                }
                seo{     
                  id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
              }
              }
            }
          }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit(
                "SET_NATURAL_INFUSIONS",
                response.data.naturalInfusionsPages[0].page
            );
            //$nuxt.$loading.finish();
        });
    },
    async getWatteBoutique({ commit, rootState }) {
        const graphqlq = {
            query: `{
              watteBoutiqueTeaPages (where:{
                country:"GLOBAL"
              }){
                id
                country
                page{
                  id
                  header_image_url
                  banner_image_url
                  header{
                              ${rootState.currentLanguage}
                              }
                  title{
                              ${rootState.currentLanguage}
                              }
                  description{
                              ${rootState.currentLanguage}
                              }
                  links{ id
                              image_url
                              title{
                              ${rootState.currentLanguage}
                              }
                              link}
                  region_teas{ id
                              header_image_url
                              intro_image_url
                              banner_image_url
                              title{
                              ${rootState.currentLanguage}
                              }
                              description{
                              ${rootState.currentLanguage}
                              }
                              }
            
             
            
                        watte_boutique_region{
                    id
                    tea_locations{
                      id
                      latitude
                      longitude
                      title{
                        id
                        ${rootState.currentLanguage}
                      }
                      description{
                        id
                        ${rootState.currentLanguage}
                      }
                      image_url
                      image_text_item{
                        id
                        ${rootState.currentLanguage}
                      }
                    }
                    topic{
                      id
                                ${rootState.currentLanguage}
                    }
                    header{
                      id
                      ${rootState.currentLanguage}
                    }
                    header_image_url
                    sub_header_image_url
                  }
                  estate_teas{
                                header_image_url
                                intro_image_url
                                banner_image_url
                                title{
                              ${rootState.currentLanguage}
                              }
                                description{
                              ${rootState.currentLanguage}
                             }}
                             seo{     
                              id 
                              meta_title
                              meta_description 
                              keywords
                              share_image_alt
                              share_image_url
                              prevent_indexing 
                          }
                }
              }
           
    }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_WATTE_BOUTIQUE", response.data.watteBoutiqueTeaPages[0].page);
            //$nuxt.$loading.finish();
        });
    },
    async getCeylonIceTea({ commit, rootState }) {
      const graphqlq = {
          query: `{
            ceylonIcedTeaPages (where:{
              country:"GLOBAL"
            }){
              id
              country
              page{
                id
                header_image_url
                banner_image_url
                intro_image_url
                title{ ${rootState.currentLanguage}}
                glass_bottle{ id
                            header_image_url
                            intro_image_url
                            banner_image_url
                            title{${rootState.currentLanguage}}
                            description{${rootState.currentLanguage}}
                            }
                tetra_pack{ id
                              header_image_url
                              intro_image_url
                              banner_image_url
                              title{${rootState.currentLanguage}}
                              description{${rootState.currentLanguage}}}
                seo{     id 
                            meta_title
                            meta_description 
                            keywords
                            share_image_alt
                            share_image_url
                            prevent_indexing 
                            }
              }
            }
          }`,
      };

      await this.$axios.$post("/graphql", graphqlq).then((response) => {
          commit("SET_ICE_TEA", response.data.ceylonIcedTeaPages[0].page);
          //$nuxt.$loading.finish();
      });
  },
    filterProducts: _.debounce(async function({ commit, rootState }, { size = 20, page = 1, format = "", type = "", mood = "", flavour = "" }) {
            await this.$axios
                .$get(`dmc-apis/filterproduct`, {
                    params: {
                        size: size,
                        page: page,
                        lang: rootState.currentLanguage,
                        teaformatName: format,
                        teatype: type,
                        teamood: mood,
                        teaflavor: flavour,
                    },
                })
                .then((response) => {
                    commit("SET_FILTERED_PRODUCTS", response);
                });
        },
        500),

    async getProductFilterData({ commit }) {
        await this.$axios.$get(`dmc-apis/get_filterby_data`).then((response) => {
            commit("SET_FILTERS", response.data);
        });
    },
    async getTeaquizResult({ commit }, { size, page, teaType, caffeinePreference, time }) {
        await this.$axios
            .$post(
                `dmc-apis/teaquiz_results?page=${page}&size=${size}&teaType=${teaType}&caffeinePreference=${teaType &
          caffeinePreference}&time=${time}`
            )
            .then((response) => {
                commit("SET_TEAQUIZ_RESULT", response.data);
            });
    },
    async getQuizDetails({ commit, rootState }) {
        const graphqlq = {
            query: `{
        dilmahQuizPages(where: { country: "${rootState.currentCountry}" }) {
          id
          country
          page {
            id
            quiz {
              id
              question_image_url
              question_header {
                  ${rootState.currentLanguage}
              }
              question {
                ${rootState.currentLanguage}
              }
              anwsers {
                ${rootState.currentLanguage}
              }
            }
          }
        }
      }`,
        };

        await this.$axios.$post("/graphql", graphqlq).then((response) => {
            commit("SET_TEA_QUIZ_DATA", response.data.dilmahQuizPages[0].page.quiz);
            //$nuxt.$loading.finish();
        });
    },
    // async getnaturalOurIngredients({ commit, rootState }) {
    //     const graphqlq = {
    //         query: `{
    //     ingredientPages(where: {
    //       country: "${rootState.currentCountry}"
    //     }) {
    //       id
    //       country
    //       page {
    //         id
    //         ingredients_items {
    //           id
    //           header_image_url
    //           intro_image_url
    //           title {
    //             ${rootState.currentLanguage}
    //           }
    //           about_ingredient {
    //             id
    //             title {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             description {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //           }
    //           medicinal_claims {
    //             id
    //             title {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             description {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //           }
    //           possible_side_effects {
    //             id
    //             title {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             description {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //           }
    //           research_links {
    //             id

    //             ${rootState.currentLanguage}
    //           }
    //           video_item {
    //             id
    //             has_header
    //             has_link
    //             link
    //             header {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             content {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             link_text {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             image_url
    //             sub_heading {
    //               id

    //               ${rootState.currentLanguage}
    //             }
    //             has_sub_heading
    //             has_video
    //             video_url
    //           }
    //         }

    //       }
    //     }
    //   }`,
    //     };

    //     await this.$axios.$post("/graphql", graphqlq).then((response) => {
    //         commit("SET_NATURAL_OUR_INGREDIENTS", response.data.ingredientPages);
    //     });
    // },
    async getinfusionProductByIngredient({ commit, state }, payload) {
        await this.$axios
            .$get(
                `dmc-apis/get_products_by_ingredients?lang=${state.currentLanguage}&ingredient=${payload.ingredient}`
            )
            .then((response) => {
                commit("SET_INFUSION_PRODUCT_BY_INGREDIENT", response.data);
            });
    },
    async geIngredients({ commit }) {
        await this.$axios
            .$get(
                `dmc-apis/get_ingredients`
            )
            .then((response) => {
                commit("SET_INGREDIENTS", response);
            });
    },
};